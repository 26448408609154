@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";
@import "/src/styles/animations";

.container {
    color: #ffffff;
    width: 100%;

    height: 545rem;
    max-height: 545rem;
    overflow-y: hidden;

    @media screen and (max-width: 767px) {
        margin-bottom: 19rem;
    }

    &.isActived {
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 5rem;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            margin-top: 40rem;
            background: transparent;
    
            @media screen and (max-width: 767px) {
                margin-top: 35rem;
            }
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #3075ff;
        }
    }

    :global {
        .ant-table {
            background: #04051e;

            font-size: 12rem;
            font-weight: bold;
            line-height: 0.8;
            text-align: left;
            color: #fff;

            margin-right: 5rem;

            @media screen and (max-width: 767px) {
                font-size: 7rem;
            }

            table {
                border-collapse: separate; 
                border-spacing: 0 3rem;
            }
        }

        .ant-table-thead > tr > th {
            background-color: #04051e;

            border-bottom: 0;

            font-size: 12rem;
            font-weight: bold;
            line-height: 0.8;
            text-align: left;
            color: #fff;
            padding: 12rem 8rem;

            position: sticky;
            top: 0;
            z-index: 1;

            @media screen and (max-width: 767px) {
                font-size: 6.5rem;
            }
        }

        .ant-table-tbody > tr > td {
            border-bottom: 0;
            padding: 10.5rem 8rem;

            @media screen and (max-width: 767px) {
                padding: 0 2rem;
            }
        }

        .rowName td:first-child {
            border-top-left-radius: 2.5rem;
            border-bottom-left-radius: 2.5rem;
        }

        .rowName td:last-child {
            border-top-right-radius: 2.5rem;
            border-bottom-right-radius: 2.5rem;
        }

        .ant-table-tbody > tr.ant-table-row {
            background-image: linear-gradient(to bottom, #020b46, #010222);

            &.active {
                background-image: unset;
                background-color: #2c76ff !important;

                position: sticky;
                top: 33rem;
                z-index: 1;
                color: #000000;

                &:hover {
                    td {
                        border: unset;
                    }
                }

                @media screen and (max-width: 767px) {
                    top: 28rem;
                }
            }

            &:hover {
                // box-sizing: border-box;
                // -moz-box-sizing: border-box;
                // -webkit-box-sizing: border-box;
                td {
                    background: transparent;
                    border-top: 1rem solid #3075ff;
                    border-bottom: 1rem solid #3075ff;

                    &:first-child {
                        border-left: 1rem solid #3075ff;
                    }
        
                    &:last-child {
                        border-right: 1rem solid #3075ff;
                    }
                }
            }
        }

        // SPINNER
        .ant-spin-nested-loading > div > .ant-spin {
            max-height: 545rem;
        }
    }
}

.tables {
    .pfp {
        width: 26.5rem;
        height: 26.5rem;
        border-radius: 50%;
        object-fit: cover;

        @media screen and (max-width: 767px) {
            width: 15rem;
            height: 15rem;
        }
    }

    .prestige {
        display: flex;
        align-items: center;

        .levelIcon {
            width: 40%;
            object-fit: cover;
            margin-right: 6rem;
        }
    }

    .value {
        margin-bottom: 0;
        line-height: 1.2;
    }

    .loadMoreContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        max-height: 545rem;

        .loadMore {
            position: fixed;
            left: 50%;
            top: 38%;

            width: 50rem;
            height: 50rem;

            @media screen and (max-width: 767px) {
                position: absolute;
                left: 50%;
                top: 50%;

                transform: translate(-50%, -50%);
            }
        }
    }
}
