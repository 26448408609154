@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;

  visibility: hidden;
  transition: visibility 0s 0.3s;

  &.containerVisible {
    transition: visibility 0s;
    visibility: visible;
  }
}

.bgMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  z-index: 0;

  animation: hide-mask 0.15s 0.15s ease-out forwards;

  .containerVisible & {
    animation: none;
  }
}

@keyframes hide-mask {
  to {
    opacity: 0;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  background: #070824;
  z-index: 1;

  animation: hide 0.15s 0.1s ease-out forwards;

  @include breakpoint("sm-max") {
    animation-name: hide-mobile;
  }

  .containerVisible & {
    animation: none;
  }

  .mainBg {
    min-width: 100vw;
    min-height: 100vh;

    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;

    background: url('./img/desktop/glow.png') no-repeat center / 85%;

    @include breakpoint("sm-max") {
      background: url('./img/mobile/glow.png') no-repeat center / 140%;
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 247.5rem;
      height: 141.5rem;

      @include breakpoint("sm-max") {
        width: 165rem;
        height: 96rem;
      }
    }

    .rayMobile {
      .topRayMobile {
        position: absolute;
        top: 0;
        right: 0;

        width: 167rem;
        height: 148rem;
      }

      .bottomRayMobile {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 166rem;
        height: 128.5rem;
      }
    }

    .rayDesktop {
      .topRayDesktop {
        position: absolute;
        top: 0;
        left: 0;

        width: 215.5rem;
        height: 285rem;
      }

      .bottomRayDesktop {
        position: absolute;
        bottom: 0;
        right: 0;

        width: 198.5rem;
        height: 270rem;
      }
    }

    .characterDesktop {
      .topCharacterDesktop {
        position: absolute;
        top: -10rem;
        right: 144.5rem;

        width: 304rem;
        height: 293rem;
      }

      .bottomCharacterDesktop {
        position: absolute;
        bottom: 37.5rem;
        left: 133.5rem;
        
        width: 190.5rem;
        height: 191rem;
      }
    }

    .characterMobile {
      .topCharacterMobile {
        position: absolute;
        top: 118.3rem;
        left: -40rem;

        width: 177rem;
        height: 175.3rem;
      }

      .bottomCharacterMobile {
        position: absolute;
        bottom: 118rem;
        right: 14.7rem;

        width: 140rem;
        height: 140rem;
      }
    }
  }
}

@keyframes hide {
  to {
    transform: translateY(100%);
  }
}

@keyframes hide-mobile {
  to {
    transform: translateY(100%);
  }
}

.loader {
  position: relative;
  z-index: 2;
  animation: disappear 0.1s ease-out forwards;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @include breakpoint("sm-max") {
  }

  .containerVisible & {
    opacity: 0;
    animation: appear 0.1s 0.2s ease-in forwards;
  }
}

@keyframes appear {
  to {
    opacity: 1;
  }
}

@keyframes disappear {
  to {
    opacity: 0;
  }
}