@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {

}

.loadMoreContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20rem 0 10rem;

    .loadMore {
        width: 50rem;
        height: 50rem;
    }
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    grid-gap: 11.5rem 9rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }
}

.item {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    width: 230rem;
    height: 229.5rem;

    border-radius: 7.5rem;
    border: solid 0.5rem #0b1a5c;

    cursor: pointer;
    transition: all .3s;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0 0 2rem 2rem #3075ff;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        height: 160rem;
    }

    .icon {
        width: 178.5rem;
        height: 70.5rem;

        margin-bottom: 30rem;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
        }
    }

    .name {
        font-size: 14rem;
        font-weight: bold;
        line-height: 1.07;
        text-align: center;
        color: #fff;

        margin-bottom: 30rem;

        @media screen and (max-width: 767px) {
            font-size: 12rem;
        }
    }
}