@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    position: fixed;

    bottom: 20rem;
    left: 50%;
    transform: translateX(calc(-50% + 25rem));

    @media screen and (max-width: 767px) {
        position: static;
        transform: unset;
        bottom: unset;
        left: unset;
    }
}

.pagRow {
    background-image: linear-gradient(to top, #070824, #0b1a5c);

    display: flex;
    align-items: center;

    .element {
        width: 25rem;
        height: 25rem;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &.isActive {
            background-image: linear-gradient(to top, #3075ff, #0b1a5c);
            border: 0.5rem solid #3075ff;
        }

        &.isDisabled {
            cursor: not-allowed;
        }

        .currentPage {
            margin-bottom: 0;
    
            font-family: Rajdhani;
            font-size: 11rem;
            line-height: 2.11;
            text-align: center;
            color: #fff;
        }

        .navButton {
            width: 17rem;
            height: 16rem;
        }
    
        .subNavButton {
            width: 14rem;
            height: 16rem;
        }
    }
}

.item {
    p {
        margin-bottom: 0;

        font-family: Rajdhani;
        font-size: 11px;
        line-height: 2.11;
        text-align: center;
        color: #fff;
    }
}