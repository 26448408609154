@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.containerHamburger {
    position: relative;
    min-width: 57.7rem;
    min-height: 32rem;
    box-sizing: border-box;
  
    background: none;
    padding: 0;
    appearance: none;
    margin: 0;
    border: none;
    height: auto;
    width: auto;
  
    cursor: pointer;
  
    .bottomLayerMobile {
        position: absolute;
        background: url('./bottom-mobile.png') no-repeat center / 100% 100%;
        min-width: 57.7rem;
        min-height: 32rem;
    }
  
    .topLayerMobile {
        background: url('./top-mobile.png') no-repeat center / 100% 100%;
        min-width: 57.7rem;
        min-height: 32rem;
    
        display: flex;
        align-items: center;
        justify-content: center;
  
        img {
            width: 17rem;
        }
    }
}