@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    background-color:#070824;
    width: 100%;
    // min-height: 100vh;
    padding: 35rem 10rem 50rem;

    border-right: 0.5rem solid #0b1a5c;
    display: flex;
    flex-direction: column;

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.logoContainer {
    display: flex;
    justify-content: flex-end;
    padding-right: 15rem;
    margin-bottom: 28rem;

    .logo {
        width: 162rem;
        min-height: 28.5rem;
    }

    .heading {
        font-size: 17.5rem;
        font-weight: 900;
        line-height: 0.73;
        text-align: left;
        color: #fff;

        margin-bottom: 0;
    }
}
