@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    width: 100%;
    display: flex;
    justify-content: center;

    #captchaFrame {
        iframe {
            width: 100px;
            .no-touch {
                .no-selection {}
            }

            #anchor {
                background: red;
            }
        }
    }
}