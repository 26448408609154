@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    border: 1rem solid #3075ff;
    border-radius: 5rem;
    background-color: #031155;

    padding: 0;

    display: flex;

    :nth-child(1) {
        border-top-left-radius: 3rem;
        border-bottom-left-radius: 3rem;
    }

    :nth-child(2) {
        border-left: 1rem solid #3075ff;
        border-right: 1rem solid #3075ff;
    }

    :nth-child(3) {
        border-right: 1rem solid #3075ff;
    }

    :nth-child(4) {
        border-top-right-radius: 3rem;
        border-bottom-right-radius: 3rem;
    }
}

.filterItem {
    padding: 0 10rem;
    cursor: pointer;

    &.active {
        background-color: #3075ff;

        .value {
            font-weight: bold;
            color: #fff;
        }
    }

    .value {
        font-size: 10rem;
        line-height: 2;
        text-align: center;
        color: #3075ff;

        margin-bottom: 0;
    }
}