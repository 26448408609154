@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    background-color: #04051e;
    color: #8b98d1;

    border: none;

    font-size: 20rem;
    line-height: 1.25;
    text-align: right;

    width: 100%;

    &::placeholder {
        color: #8b98d1;
    }
}

.loading {
    visibility: hidden;

    &::placeholder {
        visibility: hidden;
    }
}
