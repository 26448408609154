@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
}

.banner {
    width: 100%;
    height: 412rem;
    display: none;

    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none;

    @include breakpoint("sm-max") {
        display: flex;
    }
}