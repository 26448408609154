@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
}

.firstLoad {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 20rem 0 10rem;

    .loadMore {
        width: 50rem;
        height: 50rem;
    }
}

.buyButton {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 2.5rem;
    background-image: linear-gradient(to top, #3075ff, #30aaff);

    width: 173.5rem;
    height: 43.5rem;

    font-size: 17.3rem;
    font-weight: bold;
    line-height: 3.33;
    text-align: center;
    color: #fff;

    cursor: pointer;
    transition: all .3s;

    &:hover {
        transform: scale(1.02);
    }
}