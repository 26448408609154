@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";
@import "/src/styles/animations";

.inputWrapper {
    margin-bottom: 13.5rem;

    :global {
        .ant-input {
            font-family: $font-primary;
            font-size: 12.5rem;
            text-align: left;
            color: #fffefe;
            background-color: #04051e;

            @include breakpoint("sm-max") {
                font-size: 11.7rem;
            }
    
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: inset 0 0 0 50rem #04051e;
                color: #fffefe !important;
                -webkit-text-fill-color: #fffefe !important;
            }
    
            &::placeholder {
                font-weight: 500;
                color: #8b98d1;
        
                @include breakpoint("sm-max") {
                    font-size: 11.7rem;
                }
            }
        }
    
        .ant-input-suffix {
            font-size: 12.5rem;
            text-align: right;
            color: #fefefe;
            line-height: 1;
        }
    }
    
    &:global.ant-input-affix-wrapper-disabled {
        background-color: #f5f7f9;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 11rem;
    }
}

.inputHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    .label {
        font-size: 12.5rem;
        font-weight: 600;
        text-align: left;
        color: #fff;
      
        margin-bottom: 0rem;
        margin-right: 5rem;

        @media screen and (max-width: 767px) {
            font-size: 11.7rem;
        }
    }

    .error {
        font-size: 10rem;
        text-align: right;
        color: #db1616;
        margin-bottom: 0;
        width: 250rem;

        animation: $opacity-pulse;

        @media screen and (max-width: 767px) {
            font-size: 10rem;
        }
    }
}

.input {
    border: solid 1rem #1b1850;
    padding: 0 14.5rem;
    height: 30rem;
    display: flex;
    align-items: center;
    background-color: #04051e;

    &:hover {
        border: solid 1rem #3075ff !important;
    }
  
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: inset 0 0 0 50rem #3075ff !important;
        color: #fffefe !important;
        -webkit-text-fill-color: #fffefe !important;
    }
  
    @include breakpoint("sm-max") {
      height: 30rem;
    }
}