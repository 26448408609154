@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
}

.contentBox {
    width: 100%;
    position: relative;
    z-index: 100;

    padding: 36rem 36rem 37.5rem;

    @media screen and (max-width: 767px) {
        min-height: 100vh;
        padding: 33rem 25rem 50rem;
    }
}

.titleBox {
    margin-bottom: 35.5rem;
    margin-right: 12rem;

    @media screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 32.7rem;
    }

    .title {
        width: 401.5rem;
        height: 205.5rem;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}

.description {
    margin-bottom: 102rem;
    font-size: 15rem;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;

    @media screen and (max-width: 767px) {
        text-align: center;
        font-size: 11.7rem;
        margin-bottom: 250rem;
    }
}

.btnContainer {
    font-size: 15rem;
    font-weight: bold;
    text-align: center;
    color: #fff;

    @media screen and (max-width: 767px) {
    }

    .loginBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3075ff;
        height: 38rem;

        margin-bottom: 10rem;
        cursor: pointer;
        transition: $transition-all;

        &:hover {
            box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
        }

        @media screen and (max-width: 767px) {
            height: 50rem;
        }
    }

    .registerBtn {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #3d227d;
        height: 37.5rem;
        cursor: pointer;
        transition: $transition-all;

        &:hover {
            box-shadow: 0 0 10rem 0.5rem rgba(48, 117, 255, 0.5);
        }

        @media screen and (max-width: 767px) {
            height: 50rem;
        }
    }
}
