@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 200rem;

    @media screen and (max-width: 767px) {
        justify-content: unset;
    }
}

.title {
    font-size: 19rem;
    text-align: center;
    color: #8b98d1;
}

.buttonContainer {
    width: 193rem;
    height: 37.5rem;
    border-radius: 2.5rem;
    background-color: #3075ff;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: all .3s;

    &:hover {
        transform: scale(1.02);
    }

    p {
        font-size: 11rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        margin-bottom: 0;
    }
}