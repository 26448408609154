@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
  box-sizing: border-box;

  padding: 50rem;
}

.header {
  position: fixed;
  .logo {
    width: 100rem;
    height: 60rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60rem - 100rem);

  h2 {
    font-size: 40rem;
    font-weight: 900;
    line-height: 1.2;
    color: #fff;
  }

  h3 {
    font-size: 40rem;
    font-weight: 900;
    line-height: 1.2;
    color: #fff;
  }

  p {
    font-size: 18rem;
    font-weight: 900;
    line-height: 1.2;
    color: #fff;
    text-align: center;
  }
}