@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";


.container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(to top right, #35165c, #2e2d9c, #9b32c2);

  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint("sm-max") {
  }
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100;

  padding: 49.5rem 51.5rem 49.5rem 50rem;

  @include breakpoint("sm-max") {
    padding: 0;
  }

  .contentBox {
    width: 100%;
    background-color: #070824;
    display: flex;

    @include breakpoint("sm-max") {
    }

    .left {
      width: 487.5rem;
      background-color: #070824;

      @include breakpoint("sm-max") {
        width: 100%;
        min-height: 600rem;
      }
    }

    .right {
      width: 691.5rem;
      height: auto;
      position: relative;
      display: flex;

      pointer-events: none;

      @include breakpoint("sm-max") {
        display: none;
      }

      .logo {
        width: 148rem;
        position: absolute;
        z-index: 5;
        top: 35rem;
        right: 37.5rem;
      }
    }
  }
}

:global {
  html {
    // Keep this for proper scaling using relative units (rem);
    // ---------------------------------------------------------------------
    font-size: (1/1280) * 100vw;

    @include breakpoint("sm-max") {
      font-size: (1/375) * 100vw;
    }

    // Enable this to support layout proportional scaling on mobile
    //@include breakpoint("sm-max") {
    //  font-size: (1/375) * 100vw;
    //}
    // ----------------------------------------------------------------------
  }
}