@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
  border-radius: 15rem;
  box-shadow: 0 0 10.5rem 0 rgba(0, 0, 0, 0.05);
  background-color: var(--bg-content);
  overflow: hidden;
  position: relative;
  left: 0 !important;
  top: 4rem !important;
}

.scroll {
  padding: 0;
  overflow-y: auto;
}