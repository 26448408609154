@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.content {
    padding: 97rem 52.5rem 92.5rem;

    @media screen and (max-width: 767px) {
        padding: 50rem 0rem 50rem;
    }
}

.box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 100rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }

    .divide {
        width: 1rem;
        height: 371.5rem;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 767px) {
            height: 220rem;
            bottom: 23.5%;
            transform: rotate(90deg);
        }
    }

    .titleCard {
        font-size: 21rem;
        font-weight: bold;
        line-height: 1.15;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }

    .priceContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10rem;

        margin-bottom: 20rem;

        .price {
            font-size: 37rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            color: #fff;

            margin-bottom: 0;
        }

        .icon {
            width: 40rem;
            height: 40rem;
            object-fit: cover;
        }
    }

    .methodInfo {
        font-size: 15.3rem;
        line-height: 1.59;
        text-align: center;
        color: #fff;

        margin-bottom: 10rem;
    }

    .lootInfo {
        border-radius: 7.5rem;
        border: solid 0.5rem #0b1a5c;
        background-image: linear-gradient(to top, #0057eb, #3980f4);

        padding: 25.5rem 13.5rem 23.5rem;

        display: flex;
        flex-direction: column;
        align-items: center;

        .lootBox {
            width: 147rem;
            height: 59.5rem;

            margin-bottom: 20rem;
        }

        .boxInfo {
            font-size: 15rem;
            font-weight: 800;
            line-height: 1;
            text-align: center;
            color: #fff;

            margin-bottom: 0;
        }
    }

    .standardCard {
        width: 100%;
        padding: 30rem 24.5rem 30rem;
        object-fit: contain;
        border-radius: 7.5rem;
        border: solid 0.5rem #0b1a5c;
        background-image: linear-gradient(to top, #070824, #0b1a5c);

        display: flex;
        flex-direction: column;
        align-items: center;

        cursor: pointer;
        transition: all .3s;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
        }
    }

    .goldCard {
        width: 100%;
        padding: 30rem 24.5rem 30rem;
        object-fit: contain;
        border-radius: 7.5rem;
        border: solid 0.5rem #0b1a5c;
        background-image: linear-gradient(to top, #070824, #0b1a5c);

        cursor: pointer;
        transition: all .3s;

        .goldBox {
            border-radius: 7.5rem;
            border: solid 0.5rem #0b1a5c;
            background-image: linear-gradient(to top, #0057eb, #3980f4);

            padding: 25.5rem 13.5rem 23.5rem;

            display: flex;
            flex-direction: column;
            align-items: center;

            .goldLootIcon {
                width: 110rem;
                height: 44.5rem;

                margin-bottom: 23.5rem;
            }

            .label {
                font-size: 15rem;
                font-weight: 800;
                line-height: 1;
                text-align: center;
                color: #fff;
    
                margin-bottom: 0;
            }

            .swapRow {
                display: flex;
                align-items: center;
                gap: 10rem;
                margin-top: 5rem;

                margin-bottom: 23.5rem;

                .left {
                    width: 40rem;
                    height: 40rem;
                }

                .middle {
                    width: 24.5rem;
                    height: 22.5rem;
                }

                .right {
                    width: 40rem;
                    height: 40rem;
                }
            }
        }

        .goldMethods {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10rem;
        }

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
        }
    }
}