@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12rem;

    .logo {
        width: 112rem;
        height: 64rem;
        margin-bottom: 18.5rem;
    }

    .title {
        font-size: 18.5rem;
        font-weight: bold;
        text-align: center;
        color: #fff;
        margin-bottom: 0rem;
    }

    .description {
        font-size: 12.5rem;
        text-align: center;
        line-height: normal;
        color: #fff;

        margin-bottom: 17.5rem;
    }

    .btnContainer {
        width: 100%;
        height: 37rem;
    
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3075ff;
    
        font-size: 17.5rem;
        font-weight: bold;
        text-align: center;
        color: #fff;
    
        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.02);
        }

        &.disable {
            cursor: not-allowed;
            background-color: #888888;
    
            &:hover {
                transform: scale(1);
            }
        }
    }
}

