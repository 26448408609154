@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.wrapperBody {
    padding: 0;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
}

.title {
    font-size: 24rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0;
}

.description {
    font-size: 12.5rem;
    line-height: 2;
    text-align: center;
    color: #8b98d1;
    margin-bottom: 0;
}

.loadMore {
    width: 100rem;
    height: 100rem;
    margin: 20rem 0;
}