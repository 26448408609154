@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.list {

}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10rem;

    &[data=true] {

    }

    .avatarContainer {
        position: relative;

        width: 108.5rem;
        height: 108.5rem;
        border-radius: 50%;

        box-sizing: border-box;
        box-shadow: 0 0 0 2.5rem #3075ff;

        margin-bottom: 8rem;

        .avatar {
            width: 100%;
            height: 100%;
            border-radius: 50%;

            object-fit: cover;
        }

        .shieldIcon {
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: -9%;

            width: 20rem;
            height: 24.5rem;
        }
    }

    .username {
        font-family: Rajdhani;
        font-size: 17.5rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.3;
        color: #fff;

        margin-bottom: 0;
    }

    .value {
        font-family: Rajdhani;
        font-size: 14rem;
        font-weight: normal;
        text-align: center;
        line-height: 1.2;
        color: #cacaca;

        margin-bottom: 0;
    }
}