@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.headerBanner {
    position: relative;
    width: 100%;
    margin-bottom: 23rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 33rem;
    }

    .banner {
        height: 273rem;
        border-radius: 15rem;
        border: solid 0.5rem #0b1a5c;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
        }
    }

    .logoBanner {
        width: 234rem;

        position: absolute;
        left: 22rem;
        bottom: 16rem;

        @media screen and (max-width: 767px) {
            width: 156rem;
            left: 50%;
            bottom: 37rem;
            transform: translateX(-50%);
        }
    }
}

.content {
    margin-bottom: 17.5rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 32rem;
    }

    .title {
        font-size: 12rem;
        font-weight: bold;
        line-height: 1.5;
        color: #3075ff;

        margin-bottom: 0;

        @media screen and (max-width: 767px) {
            font-size: 16rem;
            text-align: center;
        }
    }

    .detail {
        font-size: 14rem;
        line-height: 1.43;
        text-align: left;
        color: #fff;

        margin-bottom: 0;

        @media screen and (max-width: 767px) {
            font-size: 16rem;
            text-align: center;
        }
    }
}