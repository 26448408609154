@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.wrapperBody {
    padding: 0;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0 15rem;

    @media screen and (max-width: 767px) {
        padding: 18rem 12rem;
    }

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 20rem;
        right: 20rem;

        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            // transform: scale(1.05);
            // box-shadow: 0 0 10rem 0.5rem rgba(255, 255, 255, 0.35);
        }
    }

    .header {
        margin-bottom: 20rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1.13;
            color: #fff;
            text-align: center;

            margin-bottom: 3rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 10rem;
            line-height: 2;
            text-align: center;
            color: #8b98d1;
            letter-spacing: -0.25rem;

            margin-bottom: 0;
        }
    }

    .info {
        display: grid;
        grid-template-columns: repeat(2, minmax(240rem, 1fr));
        grid-gap: 0 71.5rem;

        position: relative;

        @media screen and (max-width: 767px) {
            grid-gap: 46.3rem 0rem;
            grid-template-columns: 1fr;
        }

        .divide {
            width: 1rem;
            height: 100%;

            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            @media screen and (max-width: 767px) {
                height: 241rem;
                bottom: 33.5%;
                transform: rotate(90deg);
            }
        }

        .versionInfo {
            font-size: 10rem;
            line-height: 1.3;
            text-align: center;
            color: #8b98d1;

            margin-bottom: 10rem;
            letter-spacing: -0.25rem;
        }

        .recommendSpec {
            .recommendHeading {
                font-size: 10rem;
                line-height: 1.3;
                font-weight: 500;
                text-align: left;
                color: #fff;
                letter-spacing: -0.25rem;
            }

            .recommendText {
                font-size: 10rem;
                line-height: 1.3;
                text-align: left;
                color: #8b98d1;

                margin-bottom: 20rem;
                letter-spacing: -0.25rem;

                .note {
                    font-size: 9.5rem;
                    font-style: italic;
                }
            }
        }

        .macbook {
            display: flex;
            flex-direction: column;
            align-items: center;

            .infoBody {
                flex: 1;
                width: 100%;

                h2 {
                    font-size: 15rem;
                    font-weight: bold;
                    line-height: 1.5;
                    color: #fff;
                    text-align: center;
    
                    margin-bottom: 0;
                }
            }

            .macbookBeta {
                width: 152rem;
                height: 168rem;

                margin-bottom: 11rem;
                flex-shrink: 0;
            }

            .downloadBtn {
                width: 100%;
                height: 37.5rem;
                border-radius: 2.5rem;
                background-color: #3075ff;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
                transition: $transition-all;
            
                &:hover {
                    box-shadow: 0 0 10rem 0.5rem rgba(255, 255, 255, 0.35);
                }

                &.disable {
                    opacity: 0.7;
                    cursor: not-allowed;
                    user-select: none;

                    &:hover {
                        box-shadow: none;
                    }
                }

                .labelBtn {
                    font-size: 11rem;
                    font-weight: bold;
                    line-height: 4.55;
                    text-align: center;
                    color: #fff;

                    margin-bottom: 0;
                }

                .appleIcon {
                    width: 12.5rem;
                    height: 14rem;

                    margin-left: 4rem;
                    margin-right: 2rem;
                }
            }
        }

        .windows {
            display: flex;
            flex-direction: column;
            align-items: center;

            .infoBody {
                flex: 1;
                width: 100%;
                h2 {
                    font-size: 15rem;
                    font-weight: bold;
                    line-height: 1.5;
                    color: #fff;
                    text-align: center;
    
                    margin-bottom: 0;
                }
            }

            .windowsBeta {
                width: 173rem;
                height: 179rem;

                margin-bottom: 0;
                flex-shrink: 0;

                @media screen and (max-width: 767px) {
                    height: auto;
                }
            }

            .downloadBtn {
                width: 100%;
                height: 37.5rem;
                border-radius: 2.5rem;
                background-color: #3075ff;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
                transition: $transition-all;
            
                &:hover {
                    box-shadow: 0 0 10rem 0.5rem rgba(255, 255, 255, 0.35);
                }

                &.disable {
                    opacity: 0.7;
                    cursor: not-allowed;
                    user-select: none;

                    &:hover {
                        box-shadow: none;
                    }
                }

                .labelBtn {
                    font-size: 11rem;
                    font-weight: bold;
                    line-height: 4.55;
                    text-align: center;
                    color: #fff;

                    margin-bottom: 0;
                }

                .appleIcon {
                    width: 13.5rem;
                    height: 13.5rem;

                    margin-left: 4rem;
                    margin-right: 2rem;
                }
            }
        }
    }
}