@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.wrapperBody {
    padding: 0;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0 15rem;

    @media screen and (max-width: 767px) {
        padding: 18rem 12rem;
    }

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 20rem;
        right: 20rem;

        cursor: pointer;
        transition: $transition-all;
    }

    .header {
        margin-bottom: 20rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1.13;
            color: #fff;
            text-align: center;

            margin-bottom: 3rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 10rem;
            line-height: 2;
            text-align: center;
            color: #8b98d1;
            letter-spacing: -0.25rem;

            margin-bottom: 0;
        }
    }

    .info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 50rem;

        position: relative;

        @media screen and (max-width: 767px) {
            grid-gap: 46.3rem 0rem;
            grid-template-columns: 1fr;
        }

        .loadingBox {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .left {
            width: 100%;

            position: relative;

            .thumbnail {
                width: 100%;
                height: 100%;
                border-radius: 5rem;
            }

            .tokenId {
                position: absolute;
                top: 7.5rem;
                left: 7.5rem;
                padding: 0 5rem;
            
                background-color: rgba(48, 117, 255, 0.5);
                color: #fff;
            
                border-radius: 2.5rem;
            
                font-size: 11rem;
                font-weight: bold;
                letter-spacing: normal;
                text-align: center;
            }

            .sticker {
                position: absolute;
                top: 7.5rem;
                right: 7.5rem;
                padding: 0 5rem;
            
                background-color: rgba(48, 117, 255, 0.5);
                color: #fff;
            
                border-radius: 2.5rem;
            
                font-size: 11rem;
                font-weight: bold;
                letter-spacing: normal;
                text-align: center;
            }

            .isOwned {
                position: absolute;
                bottom: 7.5rem;
                right: 7.5rem;
                padding: 0 5rem;

                background-color: rgba(48, 117, 255, 0.5);
                color: #fff;

                border-radius: 2.5rem;

                font-size: 11rem;
                font-weight: bold;
                text-align: center;
            }
        }

        .divide {
            width: 1rem;
            height: 100%;

            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            @media screen and (max-width: 767px) {
                height: 241rem;
                bottom: 15%;
                transform: rotate(90deg);
            }
        }

        .right {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .dropdownContainer {
                text-align: center;
                margin-bottom: 10rem;

                .dropdownBox {
                    width: 100%;
                    background-color: #04051e;
                }

                .dropdownCustom {
                    justify-content: space-between;
                    width: 100%;
                    padding: 3rem 10rem;
                }

                .labelCustom {
                    margin: 0;
                }
            
                .dropdownLbl {
                    color: white;
                    font-size: 12rem;
                    margin-bottom: 5rem;
                }
            }

            .recipientInput {
                position: relative;
                margin-bottom: 10rem;

                .reciptientLbl {
                    font-size: 12rem;
                    color: #fff;

                    margin-bottom: 2rem;
                }

                .copied {
                    position: absolute;
                    top: 10rem;
                    right: 0;
                    z-index: 10;

                    padding: 2rem 7rem;

                    background-color: #3075ff;
                    color: white;

                    font-size: 10rem;
                    font-weight: bold;
                    border-radius: 2.5rem;
                }

                .copy {
                    width: 14.5rem;
                    height: 14.5rem;

                    cursor: pointer;
                }

                :global {
                    .ant-input {
                        background-color: #04051e;
                        height: 30.5rem;

                        list-style: none;
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        padding: 3rem 7rem;
                        color: white;
                        font-size: 12rem;
                        line-height: 1.66667;
                        background-image: none;
                        border-radius: 2px;
                        transition: all .3s;
                        
                        border-radius: 2.5rem;

                        &::placeholder {
                            font-weight: 500;
                            color: #8b98d1;
                    
                            @include breakpoint("sm-max") {
                                font-size: 11.7rem;
                            }
                        }
                    }

                    .ant-input-affix-wrapper {
                        background-color: #04051e;
                        padding: 0 7rem 0 0;
                        border-radius: 2.5rem;
                        border: solid 0.5px #1b1850;
                    }
                }

                .recipient {

                }
            }
        }
    }
}