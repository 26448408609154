@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    max-height: 175rem;
    padding-bottom: 10rem;
    padding-right: 10rem;

    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;

    // &.loading {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }

    &::-webkit-scrollbar {
        width: 5rem;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        margin-left: 10rem;

        background: gray;
        border-radius: 2.5rem;

        :hover {
        }
    }
}

.loader {
    width: 70rem;
    height: 70rem;
}

.loadMoreContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20rem 0 10rem;

    .loadMore {
        width: 50rem;
        height: 50rem;
    }
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10rem;
}

.left {
    display: flex;
    align-items: center;

    .transactionInfo {
        font-size: 14rem;
        font-weight: bold;
        line-height: 2.1;
        text-align: left;
        color: #3075ff;

        margin-bottom: 0;
        margin-right: 5rem;

        @media screen and (max-width: 767px) {
            font-size: 12rem;
        }
    }

    .waitingStatus {
        font-size: 11.5rem;
        font-weight: normal;
        color: #8b98d1;
        line-height: 2.1;

        @media screen and (max-width: 767px) {
            font-size: 9.5rem;
        }
    }

    .blueViewIcon {
        width: 14rem;
        height: 14rem;
        margin-left: 5rem;

        cursor: pointer;

        @media screen and (max-width: 767px) {
            width: 10rem;
            height: 10rem;
        }
    }
}

.right {
    .actions {
        display: flex;
        justify-content: center;
        width: 20rem;
    }

    .claimBtn {
        padding: 4.5rem 5rem;
        border-radius: 2.5rem;
        border: solid 1rem #3075ff;
        background-color: #04051e;

        font-size: 12rem;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        color: #3075ff;

        cursor: pointer;
        transition: $transition-all;

        &:hover {
            box-shadow: 0 0 2rem 1rem #3075ff;
        }

        &.claiming {
            color: #ddbf01;
            border: solid 1rem #ddbf01;
            animation: $opacity-pulse;
        }

        @media screen and (max-width: 767px) {
            padding: 3.5rem 4rem;
            font-size: 10.5rem;
        }
    }

    .successIcon {
        width: 20rem;
        height: 14.5rem;

        @media screen and (max-width: 767px) {
            width: 15rem;
            height: 10rem;
        }
    }

    .failedIcon {
        width: 14.5rem;
        height: 14rem;

        @media screen and (max-width: 767px) {
            width: 12rem;
            height: 12rem;
        }
    }
}