@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    width: 100%;
    padding: 24rem 20.5rem 24.5rem 21.5rem;
    background-image: linear-gradient(to top, #070824, #0b1a5c);
    border-radius: 15rem;
    border: solid 0.5rem #0b1a5c;
}

.content {
    position: relative;

    @media screen and (max-width: 767px) {
        position: relative;
        z-index: 100;
    }

    .manImg {
        position: absolute;
        object-fit: cover;

        width: 132.5rem;
        height: 301.5rem;
        top: -82rem;
        right: -21.5rem;

        @media screen and (max-width: 767px) {
            position: absolute;
            z-index: 1;
        }
    }

    .userInfo {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
            position: relative;
            z-index: 100;
        }
        
        margin-top: 14rem;
        margin-bottom: 7rem;
    
        .avatar {
            width: 41.5rem;
            height: 41.5rem;
            border-radius: 50%;
            object-fit: cover;
    
            margin-right: 7rem;
        }
    
        .username {
            font-size: 22.5rem;
            font-weight: bold;
            line-height: 0.67;
            text-align: left;
            color: #fff;
    
            margin-bottom: 0;

            @media screen and (max-width: 767px) {
                font-size: 18rem;
            }
        }
    }

    .accountInfo {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
            position: relative;
            z-index: 100;
        }
    
        gap: 12rem;
    
        .experience {
            display: flex;
            flex-direction: column;
            gap: 9rem 0rem;
        }
    }
}

.divider {
    height: 1rem;
    width: 100%;
    background-image: linear-gradient(to right, rgba(48, 117, 255, 0), rgba(48, 117, 255), rgba(48, 117, 255, 0));

    margin: 13rem 0;
}