@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: visibility 0.15s 0s;

  z-index: 999;

  &.isOpen {
    transition: visibility 0s 0s;
    visibility: visible;
  }
}

.fade {
  opacity: 0;
  transition: opacity 0.15s ease-in;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  .isOpen & {
    opacity: 1;
  }
}

.menuWrapper {
  position: absolute;
  overflow: auto;
  right: 0;
  top: 0;
  z-index: 6;

  display: flex;
  flex-direction: column;

  padding: 33rem 14rem 56rem;
  height: 100%;
  width: 254rem;
  background-color: #070824;

  transition: transform 0.15s ease-in;
  transform: translateX(100%);

  .isOpen & {
    transform: translateX(0);
  }
}

.alignEnd {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20rem;

  .containerHamburger {
    position: relative;
    min-width: 57.7rem;
    min-height: 32rem;
    box-sizing: border-box;

    background: none;
    padding: 0;
    appearance: none;
    margin: 0;
    border: none;
    height: auto;
    width: auto;

    cursor: pointer;

    .bottomLayerMobile {
      position: absolute;
      background: url("./bottom-mobile.png") no-repeat center / 100% 100%;
      min-width: 57.7rem;
      min-height: 32rem;
    }

    .topLayerMobile {
      background: url("./top-mobile.png") no-repeat center / 100% 100%;
      min-width: 57.7rem;
      min-height: 32rem;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 17rem;
      }
    }
  }
}

.nav {
  .linkLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 20rem;

    .logo {
      width: 210rem;
    }
  }

  .navList {
    list-style: none;
    padding: 0;
    margin: 0;

    .navItem {
      display: flex;
      align-items: center;
      padding: 15.5rem 0 15.5rem 33rem;
      margin-bottom: 5rem;

      border-radius: 2.5rem;
      height: 45rem;
      width: 100%;

      cursor: pointer;
      transition: $transition-all;

      &:hover {
        background-color: #3075ff;
        // box-shadow: 0 0 10rem 0.5rem rgba(255, 255, 255, 0.35);

        .label {
          font-weight: bold;
          color: #fff;
        }
      }

      &.current {
        background-color: #3075ff;

        .label {
          font-weight: bold;
          color: #fff;
        }

        .icon {
        }
      }

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;

        &:hover {
          background: transparent;
          transform: scale(1);

          .label {
            font-weight: normal;
            color: #8b98d1;
          }
        }
      }

      .icon {
      }

      .label {
        font-size: 11rem;
        font-weight: normal;
        line-height: 4.55;
        letter-spacing: normal;
        text-align: left;
        color: #8b98d1;

        transition: $transition-all;
      }
    }
  }
}

.divide {
  background-color: #8b98d1;
  height: 0.5rem;
  margin: 16.5rem 40rem 20.5rem 33rem;
}

.noWallet {
    margin-bottom: 14rem;
    margin-right: 40rem;
  
    padding: 3rem;
  
    border-radius: 2.5rem;
    border: solid 1rem #1b1850;
    background-color: #04051e;
  
    display: flex;
    align-items: center;
    justify-content: space-between;

    .connectBtn {
        border-radius: 1.5rem;
        background-image: linear-gradient(to top, #1658db, #3075ff);
        padding: 8rem 0;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: Poppins;
        font-size: 11rem;
        line-height: 1;
        font-weight: 600;
        color: #fff;

        cursor: pointer;
        transition: $transition-all;

        &:hover {
            transform: scale(1.015);
        }
    }
}

.walletInfo {
  margin-bottom: 14rem;
  margin-right: 40rem;

  padding: 3rem 1.5rem 3rem 9rem;

  border-radius: 2.5rem;
  border: solid 1rem #1b1850;
  background-color: #04051e;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    font-size: 9.3rem;
    font-weight: bold;
    line-height: 1.19;
    text-align: left;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right {
    border-radius: 1.5rem;
    background-image: linear-gradient(to top, #1658db, #3075ff);
    min-height: 25rem;
    padding: 0 5rem 0 5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    .jazzIcon {
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
      box-shadow: 0 0 0 1.5rem white;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 9.3rem;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #fff;

      margin-bottom: 0;
      margin-right: 5rem;
    }
  }
}

.collapse {
  padding-left: 33rem;
  margin-bottom: 30rem;

  :global {
    .ant-collapse {
      background-color: #070824;

      .ant-collapse-item {
        border-bottom: 0;

        &:hover {
        }

        .ant-collapse-header {
          padding: 0;

          .ant-collapse-arrow {
            display: none;
          }
        }

        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }

          .ant-collapse-content-active {
          }
        }
      }

      .ant-collapse-borderless {
      }
    }
  }

  .collapseCustom {
    .headerCustom {
      display: flex;
      align-items: center;
      margin-right: 40rem;

      cursor: pointer;
      transition: $transition-all;

      &:hover {
        transform: scale(1.015);

        .headerLbl {
          text-shadow: #3075ff 0 0 12rem;
        }
      }

      .loadingAvatar {
        width: 26.5rem;
        height: 26.5rem;
        border-radius: 50%;
        margin-right: 8.5rem;
        background-color: gray;

        animation: opacityPulse 2s ease-in-out infinite;
      }

      .loadingUsername {
        width: 90rem;
        height: 12rem;
        border-radius: 5rem;
        background-color: gray;
        margin-right: 10rem;

        animation: opacityPulse 2s ease-in-out infinite;
      }

      .noAvatar {
        width: 26.5rem;
        border-radius: 50%;
        margin-right: 8.5rem;

        position: relative;

        .circle {
          width: 100%;
        }
      }

      .avatar {
        width: 26.5rem;
        height: 26.5rem;
        border-radius: 50%;
        margin-right: 8.5rem;

        object-fit: cover;
      }

      .headerLbl {
        flex: 1;
        font-size: 11rem;
        font-weight: bold;
        line-height: 3.5;
        text-align: left;
        color: #fff;

        margin-bottom: 0;
        margin-right: 18.5rem;

        transition: $transition-all;
      }

      .arrow {
        width: 9.5rem;
        transform: rotate(180deg);

        &.isDropdown {
          transform: rotate(0);
        }
      }
    }

    .contentCustom {
      padding-left: 35rem;

      .contentLbl {
        font-size: 11rem;
        line-height: 3.4;
        letter-spacing: normal;
        text-align: left;
        color: #8b98d1;
        margin-bottom: 0;

        cursor: pointer;
        transition: $transition-all;

        &:hover {
          color: white;
          font-weight: bold;
          text-shadow: #3075ff 0 0 12rem;
        }
      }
    }
  }
}
