@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.content {
    padding: 50rem 0;
    color: white;
    min-height: 100vh;

    @include breakpoint("sm-max") {

    }
}

.title {
    font-size: 28rem;
    font-weight: bold;
    text-align: left;
    line-height: 1.2;
    margin-bottom: 25rem;

    @include breakpoint("sm-max") {
        font-size: 21rem;
    }
}

.separate {
    margin: 27rem 0;
}

.heading {
    font-size: 12.5rem;
    font-weight: 900;
    line-height: 1.5;
    letter-spacing: 0.2rem;

    @include breakpoint("sm-max") {
        font-size: 12.5rem;
    }
}

.paragraph {
    font-size: 11.5rem;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.2rem;

    @include breakpoint("sm-max") {
        font-size: 11.5rem;
    }
}