@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.wrapperClass {
    @media screen and (max-width: 767px) {
        padding: 120rem 25rem;
    }
}

.container {
    @media screen and (max-width: 767px) {
    }
}

.header {
    display: flex;
    align-items: center;
    position: absolute;
    top: 23.5rem;

    cursor: pointer;
    transition: $transition-all;

    &:hover {
        transform: scale(1.05);
    }

    @media screen and (max-width: 767px) {
        top: 80rem;
    }

    .backArrow {
        width: 8rem;
        height: 13rem;
        margin-right: 6.5rem;

        @media screen and (max-width: 767px) {
            width: 6.7rem;
            height: 11rem;
        }
    }

    span {
        font-size: 12.5rem;
        font-weight: bold;
        text-align: left;
        color: #fff;

        @media screen and (max-width: 767px) {
            font-size: 15rem;
        }
    }
}

.contentBox {
    width: 100%;
    height: 100%;

    padding: 36rem 36rem 37.5rem;

    @media screen and (max-width: 767px) {
        padding: 23rem 25rem;
    }
}