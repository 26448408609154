@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
  width: 100%;
  background: none;
  padding: 0;
  margin-bottom: 30rem;

  box-sizing: border-box;
  z-index: 999;

  transition: background 0.15s ease-in;

  @include breakpoint("sm-max") {
    margin-bottom: 83rem;
    // padding: 33rem 0;
  }

  &.containerScrolled {
    background: #0A0E21;
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include breakpoint("sm-max") {
    justify-content: space-between;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .right {

  }
}

.logo {
  width: 52rem;
  margin-right: 26.5rem;

  @include breakpoint("sm-max") {
    width: 57.3rem;
    margin-right: 0rem;
  }
}

.menu {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  @include breakpoint("sm-max") {
    display: none;
  }
}

.menuItem {
  &:not(:first-child) {
    margin-left: 20rem;
  }
}

.menuLink {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  font-family: $font-primary;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  font-size: 15rem;
  text-transform: uppercase;

  transition: text-shadow 0.15s ease-in;

  &:hover {
    text-shadow: #0297fe 0 0 12rem;
  }
}
