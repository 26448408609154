@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20rem;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }

    .left {
        width: 100%;
    }

    .right {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20rem;

        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
        }
    }
}

.slug {
    margin-bottom: 20rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 32rem;
    }
}

.chart {
    padding: 27rem 25rem 22.5rem 21.5rem;
    object-fit: contain;
    border-radius: 15rem;
    border: solid 0.5rem #0b1a5c;
    background-image: linear-gradient(to top, #070824, #0b1a5c);
}