@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 18.5rem;

    .logo {
        width: 112rem;
        height: 64rem;
        margin-bottom: 18.5rem;
    }

    .cameraContainer {
        width: 74rem;
        height: 74rem;
        margin-bottom: 17.5rem;

        position: relative;
        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.02);
        }

        .circle {
            width: 100%;
        }

        .camera {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 26rem;
            height: 21rem;
        }
    }

    .title {
        font-size: 17.5rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        margin-bottom: 0rem;
    }

    .description {
        font-size: 12.5rem;
        text-align: center;
        line-height: normal;
        color: #fff;
    }

    .btnContainer {
        width: 100%;
        height: 39rem;

        margin-top: 17.5rem;
    
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3075ff;

        font-size: 15rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.02);
        }

        &.disable {
            background-color: #888888;
            cursor: not-allowed;
            user-select: none;
        }
    }
}