@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    display: grid;
    grid-template-columns: 791.5rem 145rem;
    grid-gap: 20rem;

    align-items: flex-start;

    @media screen and (max-width: 767px) {
        display: unset;
    }
}

.left {
    width: 100%;

    .headerPage {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .slugStyling {
            font-size: 19rem;
            font-weight: bold;
        }

        .sponsored {
            @media screen and (max-width: 767px) {
                margin-bottom: 25rem;
            }

            .label {
                font-size: 12rem;
                line-height: 0.8;
                text-align: center;
                color: #fff;

                margin-bottom: 7rem;
            }

            .sponsoredIcon {
                width: 130rem;
                object-fit: cover;

                @media screen and (max-width: 767px) {
                    width: 250rem;
                }
            }
        }
    }

    .ranksContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.right {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}