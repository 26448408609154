@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: visibility 0.15s 0s;

  z-index: 999;

  &.isOpen {
    transition: visibility 0s 0s;
    visibility: visible;
  }
}

.fade {
  opacity: 0;
  transition: opacity 0.15s ease-in;
  background: rgba(0,0,0, 0.7);
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  .isOpen & {
    opacity: 1;
  }
}

.menuWrapper {
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: 6;

  padding: 33rem 25rem;
  height: 100%;
  width: 216rem;
  background-color: #070824;

  transition: transform 0.15s ease-in;
  transform: translateX(100%);

  .isOpen & {
    transform: translateX(0);
  }
}

.alignEnd {
  display: flex;
  justify-content: flex-end;

  .containerHamburger {
    position: relative;
    min-width: 57.7rem;
    min-height: 32rem;
    box-sizing: border-box;
  
    background: none;
    padding: 0;
    appearance: none;
    margin: 0;
    border: none;
    height: auto;
    width: auto;
  
    cursor: pointer;
  
    .bottomLayerMobile {
      position: absolute;
      background: url('./bottom-mobile.png') no-repeat center / 100% 100%;
      min-width: 57.7rem;
      min-height: 32rem;
    }
  
    .topLayerMobile {
      background: url('./top-mobile.png') no-repeat center / 100% 100%;
      min-width: 57.7rem;
      min-height: 32rem;
  
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 17rem;
      }
    }
  }
}

.menu {
  padding: 0;
  margin: 46rem 0 34rem;
  list-style: none;
}

.menuItem {
  &:not(:first-child) {
    margin-top: 30rem;
  }
}

.menuLink {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  font-family: $font-primary;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  font-size: 12rem;

  text-transform: uppercase;
  transition: text-shadow 0.15s ease-in;

  &:hover {
    text-shadow: #0297fe 0 0 10rem;
  }
}

.button {
  height: 34rem;
  width: 100%;
  margin-top: 42rem;
}

.buttonIcon {
    width: 13rem;
}