@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15rem;

    @media screen and (max-width: 767px) {
        padding: 0 0rem;
    }

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 20rem;
        right: 20rem;

        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.05);
        }
    }

    .logo {
        width: 112rem;
        height: 64rem;
        margin-bottom: 13.5rem;

        @media screen and (max-width: 767px) {
            margin-bottom: 17rem;
        }
    }

    .header {
        margin-bottom: 15rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            text-align: center;

            margin-bottom: 3rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 10rem;
            line-height: 1.6;
            text-align: center;
            color: #8b98d1;
            letter-spacing: -0.25rem;

            margin-bottom: 0;
        }
    }

    .btnContainer {
        display: grid;
        grid-template-columns: repeat(2, minmax(125rem, 1fr));
        grid-gap: 0 15rem;

        @media screen and (max-width: 767px) {
            width: 100%;
            grid-gap: 0 6.7rem;
        }

        .yseBtn {
            width: 100%;
            height: 37.5rem;
            object-fit: contain;
            border-radius: 2.5rem;
            background-color: #3075ff;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 11rem;
            font-weight: bold;
            line-height: 4.55;
            text-align: center;
            color: #fff;

            cursor: pointer;
            transition: $transition-all;
        
            &:hover {
                transform: scale(1.05);
            }

            @media screen and (max-width: 767px) {
                font-size: 11.7rem;
                height: 41rem;
            }
        }

        .cancelBtn {
            width: 100%;
            height: 37.5rem;
            border-radius: 2.5rem;
            background-color: #fff;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 11rem;
            font-weight: bold;
            line-height: 4.55;
            text-align: center;
            color: #3075ff;

            cursor: pointer;
            transition: $transition-all;
        
            &:hover {
                transform: scale(1.05);
            }

            @media screen and (max-width: 767px) {
                font-size: 11.7rem;
                height: 41rem;
            }
        }
    }
}