@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15rem;

    @media screen and (max-width: 767px) {
        padding: 0 0rem;
    }

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 20rem;
        right: 20rem;

        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.05);
        }
    }

    .logo {
        width: 112rem;
        height: 64rem;
        margin-bottom: 13.5rem;

        @media screen and (max-width: 767px) {
            margin-bottom: 17rem;
        }
    }

    .header {
        margin-top: 7rem;
        margin-bottom: 15rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            text-align: center;

            margin-bottom: 6rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 10rem;
            line-height: 1.6;
            text-align: center;
            color: #8b98d1;

            margin-bottom: 0;
        }
    }

    .form {
        width: 100%;

        .twitchLink {
            font-size: 11rem;
            font-weight: bold;
            line-height: 4.55;
            text-align: center;
            color: #fff;
            text-decoration: underline;
            cursor: pointer;
        }

        .label {
            font-size: 12.5rem;
            font-weight: 600;
            text-align: left;
            color: #fff;
          
            margin-bottom: 5rem;
    
            @media screen and (max-width: 767px) {
                font-size: 11.7rem;
            }
        }

        .twitchInput {
            border: solid 1rem #1b1850;
            padding: 0 14.5rem;
            height: 30rem;
            width: 100%;
            display: flex;
            align-items: center;
            background-color: #04051e;

            :global {
                .ant-input {
                    font-family: $font-primary;
                    font-size: 12.5rem;
                    text-align: left;
                    color: #fffefe;
                    background-color: #04051e;
        
                    @include breakpoint("sm-max") {
                        font-size: 11.7rem;
                    }
        
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        -webkit-box-shadow: inset 0 0 0 50rem #04051e;
                        color: #fffefe !important;
                        -webkit-text-fill-color: #fffefe !important;
                    }
            
                    &::placeholder {
                        font-weight: 500;
                        color: #8b98d1;
                
                        @include breakpoint("sm-max") {
                            font-size: 11.7rem;
                        }
                    }
                }
            }
        }

        .btnContainer {
            display: flex;
            justify-content: center;
            margin-top: 30rem;

            .changeBtn {
                width: 193rem;
                height: 37.5rem;
                object-fit: contain;
                border-radius: 2.5rem;
                background-color: #3075ff;
        
                display: flex;
                justify-content: center;
                align-items: center;
        
                font-size: 11rem;
                font-weight: bold;
                line-height: 4.55;
                text-align: center;
                color: #fff;
        
                cursor: pointer;
                transition: $transition-all;
            
                &:hover {
                    box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
                }

                &.notAllowed {
                    cursor: not-allowed;
                    background-color: #888888;
            
                    &:hover {
                        transform: scale(1);
                    }
                }
            
        
                @media screen and (max-width: 767px) {
                    font-size: 11.7rem;
                    height: 41rem;
                    width: 100%;
                }
            }
        }
    }
}