@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.loading {
    color: white;
    font-size: 15rem;

    &.custom {
        color: #3075ff;
        font-weight: bold;
        font-size: 17rem;
    }
}

.left {
    margin-right: 9.5rem;
}

.right {
    margin-left: 9.5rem;
}