@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
}

.header {
    width: 644rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .buyButton {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 2.5rem;
        background-image: linear-gradient(to top, #3075ff, #30aaff);

        width: 173.5rem;
        height: 43.5rem;

        font-size: 17.3rem;
        font-weight: bold;
        line-height: 3.33;
        text-align: center;
        color: #fff;

        cursor: pointer;
        transition: all .3s;
    
        &:hover {
            transform: scale(1.02);
        }
    }
}

.content {
    position: relative;
    display: grid;
    grid-template-columns: 644rem 1fr;

    grid-gap: 77rem;

    margin-top: 40rem;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        grid-gap: 40rem;
    }
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 767px) {
        display: unset;
        flex-direction: unset;
        align-items: unset;
    }

    .itemLoot {
        width: 352rem;
        height: 147rem;
        object-fit: contain;

        margin-bottom: 20rem;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
        }
    }

    .title {
        font-size: 19rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        margin-bottom: 20rem;

    }

    .buttonContainer {
        display: flex;
        margin-bottom: 40rem;

        .openButton {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 188rem;
            height: 37.5rem;
            border-radius: 2.5rem;
            background-color: #3075ff;

            margin-right: 5.25rem;

            font-size: 14rem;
            font-weight: bold;
            color: #fff;

            cursor: pointer;
            transition: all .3s;
    
            &:hover {
                transform: scale(1.02);
            }

            @media screen and (max-width: 767px) {
                font-size: 12.5rem;
            }
        }

        .backButton {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 188rem;
            height: 37.5rem;
            border-radius: 2.5rem;
            background-color: #fff;
            margin-left: 5.25rem;

            font-size: 14rem;
            font-weight: bold;
            color: #3075ff;

            cursor: pointer;
            transition: all .3s;
    
            &:hover {
                transform: scale(1.02);
            }

            @media screen and (max-width: 767px) {
                font-size: 12.5rem;
            }
        }
    }

    .lootConffinInfo {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .itemDetail {
            font-size: 14rem;
            text-align: left;
            color: #fff;

            margin-bottom: 17rem;
        }
    }

    .lootList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        grid-gap: 8rem;

        @media screen and (max-width: 767px) {
            width: 100%;
            grid-gap: 20rem;
            padding: 10rem;
            overflow-x: scroll;
        }
    }

    .lootItem {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 14.5rem 23.5rem 13.5rem 12.5rem;
        border-radius: 7.5rem;
        border: solid 0.5rem #0b1a5c;
        background-image: linear-gradient(to top, #070824, #0b1a5c);

        cursor: pointer;
        transition: all .3s;

        &.commonActive {
            transform: scale(1.02);
            box-shadow: 0 0 2rem 2rem #0ec800;
        }

        &.common {
            &:hover {
                transform: scale(1.02);
                box-shadow: 0 0 2rem 2rem #0ec800;
            }
        }

        &.rareActive {
            transform: scale(1.02);
            box-shadow: 0 0 2rem 2rem #3075ff;
        }

        &.rare {
            &:hover {
                transform: scale(1.02);
                box-shadow: 0 0 2rem 2rem #3075ff;
            }
        }

        &.epicActive {
            transform: scale(1.02);
            box-shadow: 0 0 2rem 2rem #8200d4;
        }

        &.epic {
            &:hover {
                transform: scale(1.02);
                box-shadow: 0 0 2rem 2rem #8200d4;
            }
        }

        &.legendaryActive {
            transform: scale(1.02);
            box-shadow: 0 0 2rem 2rem #ffc600;
        }

        &.legendary {
            &:hover {
                transform: scale(1.02);
                box-shadow: 0 0 2rem 2rem #ffc600;
            }
        }

        .top {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .itemImg {
                object-fit: contain;
                margin-bottom: 10rem;

                &.common {
                    width: 78rem;
                    height: 90.5rem;
        
                    @media screen and (max-width: 767px) {
                        width: 190rem;
                    }
                }
        
                &.rare {
                    width: 105.5rem;
                    height: 97.5rem;
        
                    @media screen and (max-width: 767px) {
                        width: 190rem;
                    }
                }
        
                &.epic {
                    width: 109rem;
                    height: 107rem;
        
                    @media screen and (max-width: 767px) {
                        width: 190rem;
                    }
                }
        
                &.legendary {
                    width: 96.5rem;
                    height: 86rem;
        
                    @media screen and (max-width: 767px) {
                        width: 190rem;
                    }
                }
            }
        }

        .bottom {
            .nameItem {
                font-size: 16rem;
                font-weight: bold;
                line-height: 1.41;

                &.common {
                    color: #0ec800;
                }
    
                &.rare {
                    color: #3075ff;
                }
    
                &.epic {
                    color: #8200d4;
                }
    
                &.legendary {
                    color: #ffc600;
                }
            }

            .detail {
                font-size: 11rem;
                line-height: 1.36;
                text-align: left;
                color: #fff;

                margin-bottom: 0;
            }
        }
    }
}

.divide {
    width: 1rem;
    height: 100%;

    position: absolute;
    left: 684rem;

    @media screen and (max-width: 767px) {
        height: 220rem;
        bottom: 33.5%;
        transform: rotate(90deg);
    }
}

.right {
    width: 100%;
    height: 595rem;
    object-fit: contain;
    border-radius: 7.5rem;
    border: solid 0.5rem #0b1a5c;
    background-image: linear-gradient(to top, #070824, #0b1a5c);

    overflow-y: hidden;
    position: relative;

    .rightheader {
        position: sticky;
        top: 0;
        padding: 25.5rem 29rem 0rem 27.5rem;
    }

    .description {
        font-size: 16rem;
        font-weight: bold;
        line-height: 1.25;
        text-align: left;
        color: #fff;

        &.common {
            color: #0ec800;
            text-transform: uppercase;
        }

        &.rare {
            color: #3075ff;
            text-transform: uppercase;
        }

        &.epic {
            color: #8200d4;
            text-transform: uppercase;
        }

        &.legendary {
            color: #ffc600;
            text-transform: uppercase;
        }
    }

    .devide {
        width: 100%;
        height: 0.5rem;
        background-color: #8b98d1;

        margin-bottom: 0;
    }

    .weaponList {
        padding: 10rem 29rem 10.5rem 27.5rem;
        height: 540rem;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5rem;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            margin-left: 5rem;
    
            background: #3075ff;
        }

        .weaponItem {
            .type {
                font-size: 14rem;
                font-weight: bold;
                line-height: 2.14;
                color: #fff;

                margin-bottom: 0;
            }

            .item {
                font-size: 11rem;
                line-height: 1.6;
                color: #fff;

                margin-bottom: 0;
            }
        }
    }
}

