@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    background-color:#04051e;
    width: 100vw;
    min-height: 100vh;

    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}

.sidebar {
    width: 225.5rem;
}

.main {
    width: 1054.5rem;

    padding: 50rem 50rem 50rem 46.5rem;

    @media screen and (max-width: 767px) {
        padding: 50rem 20rem 50rem 20rem;
        width: 100vw;
    }
}