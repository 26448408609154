@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    background-image: linear-gradient(to right, rgba(48, 117, 255, 0.35), rgba(48, 117, 255, 0));

    border-left: 2.5rem solid #3075ff;

    padding: 10rem 5rem 10rem;

    min-width: 146rem;
}

.value {
    font-size: 11rem;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: #fff;

    margin-bottom: 0;
}