@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {

}

.content {
    padding: 97rem 52.5rem 92.5rem;

    @media screen and (max-width: 767px) {
        padding: 50rem 20rem 50rem;
    }
}

.test {
    display: flex;
    flex-direction: column;

    span {
        font-size: 11rem;
        color: #3075ff;

        cursor: pointer;
    }
}

.box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 100rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }

    .divide {
        width: 1rem;
        height: 371.5rem;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 767px) {
            height: 220rem;
            bottom: 33.5%;
            transform: rotate(90deg);
        }
    }

    .titleCard {
        font-size: 21rem;
        font-weight: bold;
        line-height: 1.15;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }

    .lootCard {
        width: 100%;
        height: 371.5rem;
        padding: 42rem 24.5rem 90rem;
        object-fit: contain;
        border-radius: 7.5rem;
        border: solid 0.5rem #0b1a5c;
        background-image: linear-gradient(to top, #070824, #0b1a5c);

        cursor: pointer;
        transition: all .3s;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
            height: 280rem;
        }

        .lootIcon {
            width: 100%;
            height: 140rem;
            margin-top: 45rem;

            @media screen and (max-width: 767px) {
                height: auto;
                margin-top: 0;
            }
        }
    }

    .armoryCard {
        width: 100%;
        height: 371.5rem;
        padding: 42rem 57.5rem 40rem 58.5rem;
        object-fit: contain;
        border-radius: 7.5rem;
        border: solid 0.5rem #0b1a5c;
        background-image: linear-gradient(to top, #070824, #0b1a5c);

        cursor: pointer;
        transition: all .3s;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
            height: 280rem;
        }

        .armoryIcon {
            width: 100%;
            height: 234.5rem;

            @media screen and (max-width: 767px) {
                height: auto;
            }
        }
    }
}