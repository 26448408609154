@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {

}

.loaderAnim {
    width: 118rem;
    height: 117.5rem;
}