@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    display: flex;
    flex-direction: column;
}

.initialize {
    display: flex;
    justify-content: flex-start;
    height: 50rem;
    padding: 15rem;
    align-items: center;
    border: 1rem solid #ffffff;
    border-radius: 0rem;
    margin-bottom: 12rem;
    color: #ffffff;
    font-weight: bold;
}

.errorMessage {
    display: flex;
    justify-content: flex-start;
    height: 50rem;
    padding: 15rem;
    align-items: center;
    border: 1rem solid #df1e33;
    border-radius: 0;
    margin-bottom: 12rem;
    color: #df1e33;
}

.errorText {
    font-weight: bold;
}

.tryAgain {
    background: #e8edef;
    color: #111;
    margin-left: 10rem;
    border-radius: 7rem;
    padding: 2rem 5rem;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.75;
        background: #e8edef;
        color: #6f6f79;
        border-color: transparent;
    }
}

.wallet {
    display: flex;
    justify-content: space-between;
    height: 50rem;
    padding: 18rem;
    align-items: center;
    border: 1rem solid #ffffff;
    // border-radius: 8rem;
    margin-bottom: 12rem;
    cursor: pointer;
    transition: $transition-all;
  
    &:last-child {
        margin-bottom: 0;
    }
  
    &:hover {
        border-color: #3075ff;
    }
  
    &.notAllowed {
        cursor: not-allowed;
        opacity: 0.7;
        border: 1rem solid #1b1850;
    
        &:hover {
            // border-color: #ffffff;
        }
    }
}

.walletInfo {
    flex: 1;
    color: #ffffff;
}

.walletName {
    font-size: 12.5rem;
    font-weight: bold;
}

.walletIcon {
    border-radius: 10rem;
    max-width: 27rem;
}