@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {

}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    grid-gap: 9rem 9rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }
}

.emptyData {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 230rem;
    height: 229rem;

    padding: 0rem 43rem 26rem;
    border-radius: 7.5rem;
    border: solid 0.5rem #0b1a5c;
    background-image: linear-gradient(to top, #070824, #0b1a5c);

    cursor: pointer;
    transition: all .3s;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0 0 2rem 2rem #3075ff;
    }

    @media screen and (max-width: 767px) {
        padding: 20rem;
    }

    .icon {
        width: 144.5rem;
        height: 153.5rem;
        object-fit: contain;

        margin-bottom: 15rem;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: 153.5rem;
        }
    }

    .name {
        font-size: 14rem;
        font-weight: bold;
        line-height: 1.07;
        text-align: left;
        color: #fff;

        margin-bottom: 0;

        @media screen and (max-width: 767px) {
            font-size: 12rem;
        }
    }
}