@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";
@import "/src/styles/animations";

.container {
    flex: 1;
}

.nav {
    .navList {
        list-style: none;
        padding: 0;
        margin: 0;

        .navItem {
            display: flex;
            align-items: center;
            padding: 15.5rem 0 15.5rem 42.5rem;
            margin-bottom: 5rem;

            border-radius: 2.5rem;
            height: 45rem;
            width: 100%;

            cursor: pointer;
            transition: $transition-all;

            &:hover {
                background-color: #3075ff;

                .label {
                    color: #fff;
                }
            }

            &.current {
                background-color: #3075ff;

                .label {
                    font-weight: bold;
                    color: #fff;
                }

                .icon {
                }
            }

            &.disabled {
                opacity: 0.3;
                cursor: not-allowed;

                &:hover {
                    background: transparent;
                    transform: scale(1);

                    .label {
                        font-weight: normal;
                        color: #8b98d1;
                    }
                }
            }

            .icon {
                transition: .35s ease-in-out;
            }

            .label {
                font-size: 11rem;
                font-weight: normal;
                // line-height: 4.55;
                letter-spacing: normal;
                text-align: left;
                color: #8b98d1;

                transition: $transition-all;
            }
        }
    }
}

.divide {
    background-color: #8b98d1;
    height: 0.5rem;
    margin: 16.5rem 15.5rem 20.5rem 42.5rem;
}

.walletInfo {
    margin-bottom: 14rem;
    margin-right: 15rem;

    .loading {
        width: 100%;
        height: 23rem;
        background-color: gray;
        border-radius: 2.5rem;
    
        animation: opacityPulse 2s ease-in-out infinite;
    }

    .noWallet {
        padding: 3rem;

        border-radius: 2.5rem;
        border: solid 1rem #1b1850;
        background-color: #04051e;
    
        display: flex;
        align-items: center;
        justify-content: space-between;

        .connectBtn {
            border-radius: 1.5rem;
            background-image: linear-gradient(to top, #1658db, #3075ff);
            padding: 8rem 0;

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: Poppins;
            font-size: 11rem;
            line-height: 1;
            font-weight: 600;
            color: #fff;

            cursor: pointer;
            transition: $transition-all;

            &:hover {
                transform: scale(1.015);
            }
        }
    }

    .hasWallet {
        padding: 3rem 1.5rem 3rem 9rem;

        border-radius: 2.5rem;
        border: solid 1rem #1b1850;
        background-color: #04051e;
    
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left {
        font-size: 9.3rem;
        font-weight: bold;
        line-height: 1.19;
        text-align: left;
        color: #fff;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right {
        border-radius: 1.5rem;
        background-image: linear-gradient(to top, #1658db, #3075ff);
        min-height: 23rem;
        padding: 0 5rem 0 5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        .jazzIcon {
            width: 13rem;
            height: 13rem;
            border-radius: 50%;
            box-shadow: 0 0 0 1.5rem white;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            font-family: Poppins;
            font-size: 9.3rem;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #fff;

            margin-bottom: 0;
            margin-right: 5rem;
        }
    }
}

.collapse {
    padding-left: 42.5rem;

    :global {
        .ant-collapse {
            background-color: #070824;

            .ant-collapse-item {
                border-bottom: 0;

                &:hover {
                }

                .ant-collapse-header {
                    padding: 0;

                    .ant-collapse-arrow {
                        display: none;
                    }
                }

                .ant-collapse-content {
                    .ant-collapse-content-box {
                        padding: 0;
                    }

                    .ant-collapse-content-active {

                    }
                }
            }

            .ant-collapse-borderless {
            }
        }
    }

    .collapseCustom {
        .headerCustom {
            display: flex;
            align-items: center;
            margin-right: 15.5rem;

            cursor: pointer;
            transition: $transition-all;

            &:hover {
                transform: scale(1.015);

                .headerLbl {
                    text-shadow: #3075ff 0 0 12rem;
                }
            }

            .loadingAvatar {
                width: 26.5rem;
                height: 26.5rem;
                border-radius: 50%;
                margin-right: 8.5rem;
                background-color: gray;

                animation: opacityPulse 2s ease-in-out infinite;
            }

            .loadingUsername {
                width: 90rem;
                height: 12rem;
                border-radius: 5rem;
                background-color: gray;
                margin-right: 10rem;

                animation: opacityPulse 2s ease-in-out infinite;
            }

            .noAvatar {
                width: 26.5rem;
                border-radius: 50%;
                margin-right: 8.5rem;

                position: relative;

                .circle {
                    width: 100%;
                }
            }

            .avatar {
                width: 26.5rem;
                height: 26.5rem;
                border-radius: 50%;
                margin-right: 8.5rem;

                object-fit: cover;
            }

            .headerLbl {
                flex: 1;
                font-size: 11rem;
                font-weight: bold;
                line-height: 3.5;
                text-align: left;
                color: #fff;

                margin-bottom: 0;
                margin-right: 18.5rem;

                transition: $transition-all;
            }

            .arrow {
                width: 9.5rem;
                transform: rotate(180deg);

                &.isDropdown {
                    transform: rotate(0);
                }
            }
        }

        .contentCustom {
            padding-left: 35rem;

            .loadingContent {
                width: 90rem;
                height: 12rem;
                border-radius: 5rem;
                background-color: gray;
                margin-right: 10rem;
                margin-bottom: 20rem;
                margin-top: 20rem;

                animation: opacityPulse 2s ease-in-out infinite;
            }

            .contentLbl {
                font-size: 11rem;
                line-height: 3.4;
                letter-spacing: normal;
                text-align: left;
                color: #8b98d1;
                margin-bottom: 0;

                cursor: pointer;
                transition: $transition-all;

                &:hover {
                    color: white;
                    font-weight: bold;
                    text-shadow: #3075ff 0 0 12rem;
                }
            }
        }
    }
}