@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    width: 100%;
    padding: 24.5rem 23rem 23rem;

    background-image: linear-gradient(to top, #070824, #0b1a5c);
    border-radius: 15rem;
    border: solid 0.5rem #0b1a5c;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.weapon {
    display: flex;
    justify-content: center;

    margin-top: 21rem;

    img {
        width: 354rem;
        height: 165rem;

        @media screen and (max-width: 767px) {
            width: 250rem;
            height: 120rem;
        }
    }
}