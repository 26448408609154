@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    margin-bottom: 8rem;

    :global {
        .ant-switch {
            background-color: #04051e;
            border: solid 1rem #1b1850;
            
            min-width: unset;
            max-width: unset;
            width: 33rem;
            height: 16.5rem;
        }

        .ant-switch-handle {
            top: 0;
            width: 15rem;
            height: 15rem;
        }

        .ant-switch-handle:before {
            border-radius: 50%;
        }

        .ant-switch-checked {
            background-color: #3075ff;  
        }
    }
}

.label {
    font-size: 12.5rem;
    font-weight: 600;
    text-align: left;
    color: #fff;
  
    margin-bottom: 5rem;

    @media screen and (max-width: 767px) {
        font-size: 11.7rem;
    }
}