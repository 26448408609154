@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    margin-bottom: 17rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 32rem;
    }

    .slug {
        font-size: 22.5rem;
        font-weight: bold;
        line-height: 1.5;
        color: #3075ff;
        text-transform: uppercase;

        margin-bottom: 0;

        @media screen and (max-width: 767px) {
            font-size: 16rem;
            text-align: center;
        }
    }

    .description {
        font-size: 14rem;
        line-height: 1.43;
        text-align: left;
        color: #fff;

        margin-bottom: 0;

        @media screen and (max-width: 767px) {
            font-size: 16rem;
            text-align: center;
        }
    }

    .elementContainer {
        display: flex;
        justify-content: center;
    }

    .devide {
        width: 100%;
        height: 1rem;
        background-color: #8b98d1;
        margin-top: 100rem;

        &.normal {
            margin-top: 17rem;
        }
    }
}