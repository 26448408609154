@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    width: 100%;
    border-radius: 15rem;
    border: solid 0.5rem #0b1a5c;
    background-image: linear-gradient(to top, #070824, #0b1a5c);

    padding: 27rem 25rem 22.5rem 21.5rem;

    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
        padding: 20rem 0rem 20rem 0rem;
    }
}

.header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        padding: 0rem 20rem;
    }
}

.divider {
    height: 1rem;
    object-fit: contain;
    background-color: #3075ff;

    position: absolute;

    bottom: 41rem;
    left: 40rem;
    right: 50rem;

    @media screen and (max-width: 767px) {
        left: 19rem;
        right: 18.5rem;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        bottom: 51rem;
        left: 55rem;
        right: 59rem;
    }
}

.chart {
}