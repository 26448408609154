@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
}

.content {
    padding: 134rem 0rem 157rem;

    @media screen and (max-width: 767px) {
        padding: 50rem 20rem 50rem;
    }
}

.box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 72rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 270rem;
    height: 270rem;
    padding: 20rem 33rem 28.5rem 33.5rem;
    border-radius: 7.5rem;
    border: solid 0.5rem #0b1a5c;
    background-image: linear-gradient(to top, #070824, #0b1a5c);

    cursor: pointer;
    transition: all .3s;

    &:hover {
        transform: scale(1.02);
    }


    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.divide1 {
    width: 1rem;
    height: 270rem;

    position: absolute;
    left: 32%;
    transform: translateX(-50%);

    @media screen and (max-width: 767px) {
        height: 320rem;
        top: 15%;
        left: 51%;
        transform: rotate(90deg);
    }
}

.divide2 {
    width: 1rem;
    height: 270rem;

    position: absolute;
    left: 68%;
    transform: translateX(-50%);

    @media screen and (max-width: 767px) {
        height: 320rem;
        bottom: 15.5%;
        left: 51%;
        transform: rotate(90deg);
    }
}

.title {
    font-size: 21rem;
    font-weight: bold;
    line-height: 1.19;
    text-align: center;
    color: #fff;

    text-transform: uppercase;
    margin-bottom: 0;
    flex: 1;
}

.img1 {
    width: 162rem;
    height: 165rem;
    object-fit: cover;
}

.img2 {
    width: 167.5rem;
    height: 154rem;
    object-fit: cover;
}

.img3 {
    width: 171.5rem;
    height: 182rem;
    object-fit: cover;
}