@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
}

.banner {
    width: 100%;
    height: 100%;

    @include breakpoint("sm-max") {
        display: none;
    }
}