@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
    :global {
        .Toastify__toast-container--top-right {
            width: 413rem;
            padding: 0;
    
            top: 25rem;
            right: 25rem;
    
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    
        .Toastify__toast-container--top-center {
            left: 25rem;
            right: 25rem;
            top: 30rem;
    
            @media screen and (max-width: 767px) {
                width: auto;
            }
        }
    
        .Toastify__toast {
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
        }
    
        .Toastify__toast-body {
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    .toastContainer {
        border-radius: 7.5rem;
        border: solid 1.5rem #202429;
        background-color: #070824;

        @media screen and (max-width: 767px) {
            border-radius: 7.5rem;
            border: solid 1.5rem #202429;
            background-color: #070824;
        }
    }
}

.toastContent {
    display: grid;
    grid-template-columns: 15.6% 76% 8.4%;

    .toastLeftContent {
        background-color:#3075ff;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 64.5rem;
        min-height: 95rem;

        @media screen and (max-width: 767px) {
            width: 51rem;
        }

        .block {
            width: 38rem;
            height: 38.5rem;

            @media screen and (max-width: 767px) {
            }
        }
    }

    .toastRightContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 10rem 20rem;

        @media screen and (max-width: 767px) {
        }

        h2 {
            font-family: Poppins;
            font-size: 20rem;
            font-weight: bold;
            line-height: 1.6;
            text-align: center;
            color: #fff;

            margin-bottom: 0;

            @media screen and (max-width: 767px) {
            }
        }

        p {
            font-family: Poppins;
            font-size: 12.5rem;
            font-style: normal;
            line-height: 1.6;
            text-align: center;
            color: #fff;
            word-wrap: break-word;
            width: 90%;

            margin-bottom: 0;

            @media screen and (max-width: 767px) {
            }
        }
    }

    .closeContainer {
        text-align: center;

        @media screen and (max-width: 767px) {
            width: 25.7rem;
        }
  
        .close {
            width: 18rem;
            height: 18rem;

            margin: 15rem 10rem 0 0;

            @media screen and (max-width: 767px) {
                width: 18rem;
                height: 18rem;

                margin: 10rem 0 0 0;
            }
        }
    }
}