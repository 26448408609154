@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    position: relative;
    z-index: 10;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    position: absolute;
    bottom: 80rem;
    left: 47.5%;
    transform: translateX(-50%);

    width: 35rem;
    height: 35rem;
    border-radius: 50%;
    border: 3rem solid gray;
    border-right-color: white;
    animation: spin 1s ease infinite;

    @include breakpoint("sm-max") {
        width: 30rem;
        height: 30rem;

        left: 45%;
        bottom: 50rem;
    }
}

.isShow {
    display: unset;
}

.isNone {
    display: none;
}

.selectedContainer {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 2s;

    &.isShow {
        display: none;
    }

    .itemSelected {
        width: 400rem;
        // object-fit: cover;
        // aspect-ratio: 16/12;

        animation: fade-up 0.5s;

        @include breakpoint("sm-max") {
            width: 300rem;
            height: 250rem;
        }
    }
}

.devide {
    position: absolute;
    border: none;
    width: 1.5rem;
    height: 155rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(to bottom, rgba(254, 254, 255, 0.08), rgb(255, 255, 255), rgba(255, 255, 255, 0.08));
}

.itemContainer {
    width: 200rem;
    height: 120rem;
    margin: 0 5rem;
    border-radius: 5rem;

    background-color: #0b2352;
}

.item {
    width: 100%;
    height: 100%;
    border-radius: 5rem;
}

.classRedItem {
    &.Common {
        border-bottom: 4rem solid #0ec800;
    }

    &.Rare {
        border-bottom: 4rem solid #3075ff;
    }

    &.Epic {
        border-bottom: 4rem solid #8200d4;
    }

    &.Legendary {
        border-bottom: 4rem solid #ffc600;
    }
}

.raffleRoller {
    display: none;

    width: 100%;
    position: absolute;
    z-index: 10;

    margin-bottom: 20rem;

    &.isShow {
        display: unset;
    }
}

.raffleRollerHolder {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 2rem;
}

.raffleRollerContainer {
    position: relative;
    overflow-x: hidden;
    width: 9999999999999999999px;
    max-width: 999999999999999999px;
    margin-left: 0;
    transition: all 8s cubic-bezier(.08,.6,0,1);
    display: flex;
    align-items: center;
    padding: 0 5rem;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}