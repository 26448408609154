@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.wrapperBody {
    padding: 0;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 25rem 26rem 0;

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 25rem;
        right: 25rem;
        z-index: 100;

        cursor: pointer;
        transition: $transition-all;
    }
}

.header {
    .heading {
        font-size: 20rem;
        font-weight: bold;
        line-height: 1.25;
        color: #fff;
        text-align: left;

        margin-bottom: 3rem;

        @media screen and (max-width: 767px) {
            font-size: 16rem;
        }
    }
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 17.5rem;
    font-weight: bold;
    line-height: 2.86;
    text-align: center;
    color: #fff;

    border-radius: 7.5rem;
    height: 50rem;
    margin: 0 0 40rem;

    background-color: #3075ff;
    cursor: pointer;
    transition: $transition-all;

    &:hover {
        box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
    }

    @media screen and (max-width: 767px) {
    }

    .whiteViewIcon {
        width: 14.5rem;
        height: 14.5rem;
        margin-left: 5rem;
    }
}

.step1 {
    .main {
        margin-top: 20rem;
        margin-bottom: 25rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            color: #fff;
            line-height: 1;
            text-align: center;

            margin-bottom: 10rem;

        }

        .description {
            font-size: 12.5rem;
            line-height: 1.5;
            text-align: center;
            color: #8b98d1;

            margin-bottom: 0;
        }
    }
}

.step2 {
    .loaderContainer {
        display: flex;
        justify-content: center;
        margin: 15rem 0 10rem;
    }

    .main {
        margin-top: 20rem;
        margin-bottom: 25rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            color: #fff;
            line-height: 1;
            text-align: center;

            margin-bottom: 10rem;
        }

        .description {
            font-size: 12.5rem;
            line-height: 1.5;
            text-align: center;
            color: #8b98d1;

            margin-bottom: 0;
        }
    }
}

.step3 {
    .loaderContainer {
        display: flex;
        justify-content: center;
        margin: 15rem 0 30rem;

        .completeIcon {
            width: 108rem;
            height: 108rem;
        }
    }

    .main {
        margin-top: 20rem;
        margin-bottom: 25rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            color: #fff;
            line-height: 1;
            text-align: center;

            margin-bottom: 30rem;
        }
    }
}