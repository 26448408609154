@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 112rem;
        height: 64rem;
        margin-bottom: 18.5rem;
    }

    h2 {
        font-size: 17.5rem;
        font-weight: bold;
        text-align: center;
        color: #fff;
        margin-bottom: 0rem;
    }

    p {
        font-size: 12.5rem;
        text-align: center;
        color: #fff;
        margin-bottom: 30rem;
    }
}

.footer {
    margin: 0 20rem;

    .closeBtn {
        width: 100%;
        height: 38rem;
    
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3075ff;
    
        font-size: 15rem;
        font-weight: bold;
        text-align: center;
        color: #fff;
    
    
        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.02);
        }
    }
}