@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
            height: 100%;
        }

        .ant-modal-body {
            height: 100%;

            @media screen and (max-width: 767px) {
                padding: 20rem 0;
            }
        }
    }
}

.wrapperBody {
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15rem;

    height: 100%;

    @media screen and (max-width: 767px) {
        padding: 0;
    }

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 20rem;
        right: 20rem;

        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.05);
        }
    }

    .header {
        margin-bottom: 15rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            text-align: center;

            margin-bottom: 6rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 14rem;
            line-height: 1.6;
            text-align: center;
            color: #fff;

            margin-bottom: 0;
        }
    }

    .box {
        width: 100%;
        transition: ease-in .3s;

        flex: 1;

        @media screen and (max-width: 767px) {
            margin-top: 10rem;
            padding: 0 10rem;
        }

        .leftBubble {
            position: absolute;
            left: 75rem;
            bottom: 20rem;

            width: 120rem;
            height: 120rem;
        }

        .rightBubble {
            position: absolute;
            top: 120rem;
            right: 70rem;

            width: 101rem;
            height: 101rem;
        }

        .list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 5rem;

            padding: 0 50rem;
            margin-bottom: 23rem;

            @media screen and (max-width: 767px) {
                grid-template-columns: 1fr 1fr;
                padding: 0 0rem;
            }

            .item {
                display: flex;
                justify-content: center;
                align-items: flex-end;
            
                padding: 0rem 0rem 0rem 0rem;
                border-radius: 7.5rem;
                border: solid 0.5rem #0b1a5c;

                width: 100%;
                height: 90rem;

                @media screen and (max-width: 767px) {
                    height: 120rem;
                }
            }

            .name {
                font-size: 7rem;
                font-weight: bold;
                line-height: 1.07;
                text-align: center;
                color: #fff;
                text-transform: uppercase;
        
                text-align: center;
        
                margin-bottom: 10rem;
        
                @media screen and (max-width: 767px) {
                    font-size: 10rem;
                }
            }
        }
    }

    .buttonContainer {
        display: flex;
        font-size: 14rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .buyLoot {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 185rem;
        height: 37.5rem;
        border-radius: 2.5rem;
        background-color: #3075ff;

        margin-right: 7rem;

        cursor: pointer;
        transition: all .3s;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 10rem;
        }
    }

    .cancel {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 185rem;
        height: 37.5rem;
        border-radius: 2.5rem;
        background-color: #fff;
        color: #3075ff;

        margin-left: 7rem;

        cursor: pointer;
        transition: all .3s;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
}