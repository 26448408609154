@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    display: grid;
    padding: 10rem;

    grid-template-columns: repeat(4, minmax(200rem, 1fr));
    grid-gap: 20rem;

    @include breakpoint("sm-max") {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.empty {
    font-size: 19rem;
    text-align: center;
    color: #8b98d1;

    margin-top: 150rem;
}

.loadMoreContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20rem 0 10rem;

    .loadMore {
        width: 50rem;
        height: 50rem;
    }
}

.item {
    position: relative;

    border-radius: 10rem;
    box-shadow: 0 0 5rem 0.5rem rgba(48, 117, 255, 0.5);

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: all .3s;

    &.isActive {
        transform: scale(1.08);
    }

    &.isImmutable {
        box-shadow: 0 0 5rem 2rem #09a0b5;
    }

    &:hover {
        transform: scale(1.05);
    }

    .tokenLbl {
        position: absolute;
        top: 10rem;
        left: 10rem;
        z-index: 10;
    
        font-weight: bold;
        color: rgba(48, 117, 255, 1);
        font-size: 18rem;
        line-height: 1;
    
        border-radius: 5rem;
    
        &.isImx {
            color: #09a0b5;
        }
    }
    
    .pending {
        position: absolute;
        top: 7.5rem;
        left: 7.5rem;
        padding: 0 5rem;
    
        background-color: rgba(48, 117, 255, 0.5);
        color: #fff;
    
        border-radius: 2.5rem;
    
        font-size: 11rem;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
    }
    
    .thumbnail {
        width: 100%;
        height: 100%;
        border-radius: 10rem;
    }
    
    .sticker {
        position: absolute;
        top: 7.5rem;
        right: 7.5rem;
        padding: 0 5rem;
    
        background-color: rgba(48, 117, 255, 0.5);
        color: #fff;
    
        border-radius: 2.5rem;
    
        font-size: 11rem;
        font-weight: bold;
        letter-spacing: normal;
        text-align: center;
    }
}
