@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    position: relative;
    z-index: 1000;

    canvas {
        height: 185rem !important;
        // width: 100% !important;

        @media screen and (max-width: 767px) {
            height: 170rem !important;
        }
    }
}