@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {

    @media screen and (max-width: 767px) {
    }
}

.title {
    font-size: 12rem;
    font-weight: bold;
    line-height: 4.17;
    text-align: center;
    color: #fff;

    margin-bottom: 0rem;
}

.footerList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 25rem;
    grid-gap: 10rem 0rem;
    justify-items: center;

    .footerItem {
        width: 31rem;
        height: 31rem;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3075ff;

        cursor: pointer;
        transition: $transition-all;

        .footerIcon {

        }

        &:hover {
            box-shadow: 0 0 10rem 0.5rem rgba(255, 255, 255, 0.35);
        }
    }
}