@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
  transition: opacity 0.15s ease-in;
  background: rgba(0,0,0, 0.2);
  backdrop-filter: blur(5rem);

  .head {
    background: #070824;
    min-height: 45rem;
    padding: 17rem 60rem 17rem 23rem;
  }

  .title {
    font-family: $font-primary;
    font-size: 15rem;
    font-weight: 600;
    text-align: left;
    color: white;
    margin: 0;
  }

  .body {
    padding: 6rem 23rem 30rem;

    &.noTitle {
      padding: 20rem 23rem 30rem;
    }

    &.custom {
      padding: 0 0 10rem;
    }
  }

  :global {
    .ant-modal-content {
      border-radius: 15rem;
      background: #070824;
      padding: 0;
      overflow: hidden;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-close-x {
      margin-right: 7rem;
      margin-top: 17rem;
    }
  }
}