@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
            height: 100%;
        }

        .ant-modal-body {
            height: 100%;

            @media screen and (max-width: 767px) {
                padding: 20rem 0;
            }
        }
    }
}

.wrapperBody {
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 14rem;

    height: 100%;

    @media screen and (max-width: 767px) {
        padding: 0;
    }

    .header {
        margin-bottom: 15rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1;
            color: #fff;
            text-align: center;

            margin-bottom: 6rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }
    }

    .box {
        width: 100%;
        transition: ease-in .3s;

        .sectionRow {
            font-size: 14rem;
            color: #fff;

            margin-bottom: 0;
        }

        .noteRow {
            font-size: 12rem;
            color: #fff;
            margin-top: 10rem;
            margin-bottom: 25rem;
        }
    }

    .buttonContainer {
        display: flex;
        font-size: 14rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .cancel {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 185rem;
        height: 37.5rem;
        border-radius: 2.5rem;
        background-color: #3075ff;
        color: white;

        margin-left: 7rem;

        cursor: pointer;
        transition: all .3s;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
}