$opacity-pulse: opacityPulse 2s ease-in-out infinite;

@mixin animateFloatSquare($name: float, $distance: 15px, $duration: 15s, $easing: ease-in-out) {
  animation: $name $duration $easing infinite alternate;

  @keyframes #{$name} {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(0, -$distance);
    }
    40% {
      transform: translate(-$distance, 0);
    }
    60% {
      transform: translate(0, $distance);
    }
    80% {
      transform: translate($distance, 0);
    }
    100% {
      transform: translate(0, -$distance);
    }
  }
}

@mixin animateSkeleton() {
  opacity: .7;
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: rgba($color: #04051f, $alpha: 0.8);
    }

    100% {
      background-color: rgba($color: #04051f, $alpha: 0.1);
    }
  }
}

@keyframes opacityPulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}