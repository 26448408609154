@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";
@import "/src/styles/animations";

.container {
    display: flex;
    flex-direction: column;
}

.addressExists {
    font-size: 10rem;
    text-align: right;
    color: #1658db;
    margin-bottom: 5rem;

    animation: $opacity-pulse;

    @media screen and (max-width: 767px) {
        font-size: 10rem;
    }
}

.titleForm {
    font-size: 22.5rem;
    font-weight: bold;
    text-align: left;
    color: #fff;
    line-height: 1.2;

    margin-bottom: 22.5rem;

    @media screen and (max-width: 767px) {
        font-size: 18.3rem;

        margin-bottom: 18rem;
    }
}

.metamask {
    border: solid 0.5rem #1b1850;
    background-color: #04051e;

    padding: 15rem 14.5rem 14.5rem 14.5rem;
    margin-bottom: 15rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.highlight {
        border: solid 1rem #1658db;
        animation: $opacity-pulse;
    }

    .left {
        .connectText {
            font-size: 10rem;
            text-align: left;
            color: #fff;

            margin-bottom: 3rem;

            &.noConnect {
                font-size: 15rem;

                @media screen and (max-width: 767px) {
                    font-size: 13.3rem;
                }
            }
        }
    
        .account {
            display: flex;
            align-items: center;

            .accountIcon {
                width: 17.5rem;
                height: 17.5rem;
                margin-right: 8rem;
            }

            .accountAddress {
                font-size: 12.5rem;
                font-weight: 600;
                text-align: left;
                color: #fff;

                @media screen and (max-width: 767px) {
                    font-size: 11.5rem;
                }
            }
        }
    }

    .right {
        font-size: 12.5rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        @media screen and (max-width: 767px) {
            font-size: 11.5rem;
        }
    
        .changeBtn {
            width: 110.5rem;
            height: 33.5rem;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: #3075ff;

            cursor: pointer;
            transition: $transition-all;

            &:hover {
                box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
            }

            &.notAllowed {
                cursor: not-allowed;
                user-select: none;
                opacity: 0.5;

                &:hover {
                    transform: scale(1);
                }
            }

            @media screen and (max-width: 767px) {
                height: 34rem;
            }
        }
    }
}

.hcaptcha {
    margin-bottom: 10rem;

    .warning {
        font-size: 12rem;
        text-align: center;
        color: #1658db;
        margin-bottom: 8rem;

        animation: $opacity-pulse;

        @media screen and (max-width: 767px) {
            font-size: 10rem;
        }
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 10rem;
    }
}

.createBtn {
    background-color: #203582;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45rem;
    margin-bottom: 25rem;

    font-size: 17.5rem;
    font-weight: bold;
    color: #fff;

    cursor: pointer;
    transition: $transition-all;

    &:hover {
        box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
    }

    &.notAllowed {
        cursor: not-allowed;
        background-color: #888888;

        &:hover {
            box-shadow: none;
            transform: scale(1);
        }
    }

    @media screen and (max-width: 767px) {
        font-size: 15rem;
        height: 50rem;
        margin-bottom: 20rem;
    }
}

.footer {
    display: flex;
    justify-content: center;

    .firstText {
        font-size: 12.5rem;
        text-align: left;
        color: #fff;

        @media screen and (max-width: 767px) {
            font-size: 11rem;
        }
    }

    .lastText {
        font-size: 12.5rem;
        font-weight: bold;
        color: #1658db;
        text-align: left;
        cursor: pointer;

        @media screen and (max-width: 767px) {
            font-size: 11rem;
        }
    }
}