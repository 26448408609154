@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    .title {
        font-size: 15rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #3075ff;

        @media screen and (max-width: 767px) {
            font-size: 13rem;
        }
    }

    .description {
        font-size: 22.5rem;
        font-weight: bold;
        line-height: 0.67;
        text-align: left;
        color: #fff;

        margin-bottom: 0;

        &.rightAlign {
            text-align: right;
        }

        @media screen and (max-width: 767px) {
            font-size: 13rem;
        }
    }
}