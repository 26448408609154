@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    transition: opacity 0.15s ease-in;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5rem);

    border-radius: 10rem;
}