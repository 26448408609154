@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    display: grid;
    grid-template-columns: repeat(4, minmax(221rem, 1fr));
    grid-gap: 0 24rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 221rem;
        grid-gap: 17rem 0;
        justify-content: center;
        width: 100%;
    }
}

.content {
}

.cardItem {
    border-radius: 15rem;
    border: solid 0.5rem #0b1a5c;
    background-image: linear-gradient(to top, #070824, #0b1a5c);

    padding: 0rem 13rem;
    min-height: 270rem;

    display: flex;
    flex-direction: column;

    cursor: pointer;
    transition: $transition-all;

    &:hover {
        transform: scale(1.03);
        box-shadow: 0 0 10rem 3rem #3075ff;
    }

    @media screen and (max-width: 767px) {
        border-radius: 7.5rem;
    }

    .cardBanner {
        height: 155rem;
        display: flex;
        justify-content: center;

        position: relative;

        .banner {
            flex-shrink: 0;
        }

        .bgAnimation {
            position: absolute;
            top: -30rem;
            left: 0;
            width: 100%;

            pointer-events: none;
        }
    }
}

.cardInfo {
    flex: 1;

    .title {
        font-size: 15rem;
        font-weight: bold;
        line-height: 1.5;
        color: #fff;
        margin-bottom: 0rem;

        // text-transform: uppercase;
    }

    .detailView {
        display: flex;
        align-items: center;

        .detail {
            font-size: 9.5rem;
            line-height: 1.4;
            text-align: left;
            color: #8b98d1;
    
            margin-bottom: 0;
        }
    }
}

.cardFooter {
    display: flex;
    align-items: center;

    margin-bottom: 17rem;

    .coming {
        font-size: 10rem;
        font-weight: bold;
        line-height: 1.5;
        text-align: left;
        color: #3075ff;

        margin-bottom: 0;
        margin-right: 8rem;
    }

    .rightArrow {
        margin-top: 1rem;
        width: 15rem;
        height: 7.5rem;
    }
}