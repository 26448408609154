@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 11rem 11rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }
}

.statisticItem {

}