@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";
@import "/src/styles/animations";

.container {
    display: flex;
    flex-direction: column;
}

.titleForm {
    font-size: 22.5rem;
    font-weight: bold;
    text-align: left;
    color: #fff;
    line-height: 1.2;

    margin-bottom: 22.5rem;

    @media screen and (max-width: 767px) {
        font-size: 18.3rem;

        margin-bottom: 18rem;
    }
}

.hcaptcha {
    margin-top: 30rem;
    margin-bottom: 20rem;

    .warning {
        font-size: 12rem;
        text-align: center;
        color: #1658db;
        margin-bottom: 8rem;

        animation: $opacity-pulse;

        @media screen and (max-width: 767px) {
            font-size: 10rem;
        }
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 20rem;
    }
}

.sendBtn {
    background-color: #3075ff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45rem;
    margin-bottom: 25rem;

    font-size: 17.5rem;
    font-weight: bold;
    color: #fff;

    cursor: pointer;
    transition: $transition-all;

    &:hover {
        box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
    }

    &.notAllowed {
        cursor: not-allowed;
        background-color: #888888;

        &:hover {
            box-shadow: none;
            transform: scale(1);
        }
    }

    @media screen and (max-width: 767px) {
        font-size: 15rem;
        height: 50rem;
        margin-bottom: 15rem;
    }
}