@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    margin-top: 10rem;
}

.empty {
    display: flex;
    justify-content: center;

    margin-top: 150rem;

    .emptyText {
        font-size: 20rem;
        font-weight: bold;
        line-height: 1.07;
        text-align: center;
        color: #fff;
    }
}

.content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    grid-gap: 9rem 9rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }
}

.item {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    padding: 0rem 0rem 0rem 0rem;
    border-radius: 7.5rem;
    border: solid 0.5rem #0b1a5c;

    height: 153rem;

    cursor: pointer;
    transition: all .3s;

    &.isSelected {
        border: solid 0.5rem #ff0000;
        box-shadow: 0 0 2rem 2rem #ff0000;

        &:hover {
            transform: scale(1.02);
            box-shadow: 0 0 2rem 2rem #ff0000;
        }
    }

    &:hover {
        transform: scale(1.02);
        box-shadow: 0 0 2rem 2rem #3075ff;
    }

    @media screen and (max-width: 767px) {
        padding: 15rem 10rem;

        width: 100%;
        height: 150rem;
    }

    .name {
        font-size: 10rem;
        font-weight: bold;
        line-height: 1.07;
        text-align: center;
        color: #fff;
        text-transform: uppercase;

        text-align: center;

        margin-bottom: 10rem;

        @media screen and (max-width: 767px) {
            font-size: 10rem;
        }
    }
}