@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
  padding: 3rem 0 !important;
  min-width: 107rem;
  box-shadow: 0.5rem 4.5rem 14.5rem 0 rgba(0, 0, 0, 0.17) !important;
  border-radius: 6rem !important;
  background-color: #1b1850;

  :global {
    .ant-dropdown {
      .ant-dropdown-placement-bottomLeft {
        left: -10rem !important;
      }
    }
  }
}

.items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  padding: 5.7rem 10rem;

  font-size: 12.5rem;
  line-height: 1;
  text-align: left;
  color: #fff;

  cursor: pointer;
  transition: all 0.14s ease-in-out;

  &:hover {
    background-color: #3075ff;
    border-radius: 6rem;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.isSelected {
    background-color: #3075ff;
    border-radius: 6rem;
  }

  .icon {
    width: 17.5rem;
    height: 17.5rem;
    border-radius: 50%;
    margin-right: 5rem;
  }
}