@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.wrapperBody {
    padding: 0;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0;

    @media screen and (max-width: 767px) {
        padding: 18rem 0rem;
    }

    .header {
        padding: 20rem 26rem 20rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1.25;
            color: #fff;
            text-align: left;

            margin-bottom: 3rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 12.5rem;
            line-height: 1.6;
            text-align: center;
            color: #8b98d1;
        }
    }

    .divide {
        width: 100%;
        height: 1rem;
        background-color: #3075ff;
    }

    .listContainer {
        padding: 17rem 25rem 20rem;

        @media screen and (max-width: 767px) {
            padding: 17rem 15rem 20rem;
        }

        .title {
            font-size: 17.5rem;
            line-height: 1;
            text-align: left;
            color: #fff;

            margin-bottom: 10rem;
        }


        .empty {
            font-size: 16rem;
            line-height: 1;
            text-align: center;
            color: #fff;

            margin-bottom: 0rem;
            opacity: 0.5;
        }

    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 17.5rem;
        font-weight: bold;
        line-height: 2.86;
        text-align: center;
        color: #fff;

        border-radius: 7.5rem;
        height: 50rem;
        margin: 0 25rem 20rem;

        background-color: #3075ff;
        cursor: pointer;
        transition: $transition-all;

        &:hover {
            box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
        }

        @media screen and (max-width: 767px) {
        }
    }
}