@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    margin-bottom: 64rem;

    @media screen and (max-width: 767px) {
        margin-bottom: 28rem;
    }
}

.title {
    font-size: 11rem;
    font-weight: normal;
    line-height: 0.8;
    color: #fff;

    text-transform: uppercase;

    margin-bottom: 2rem;

    @media screen and (max-width: 767px) {
        text-align: center;
    }
}

.value {
    font-size: 25rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1.6rem;
    text-align: center;
    color: #fff;

    margin-bottom: 0;
}