* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: auto;
}

body, h1, h2, h3, h4, h5, p, a {
  color: var(--font-color-dark);
}

body {
  background: #0C101E;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

input[type=text], textarea, button {
  outline: none;
}

input[type=text]:focus, textarea:focus, button:focus, a:focus {
  outline: none;
}

.ant-modal-centered .ant-modal {
  padding: 50rem 0;
}

.slider {
  height: 100%;
  width: 100%;
  margin: 100rem auto;
}

.slick-slide {
  width: 200rem;
  height: 100%;
  margin: 0rem 5rem;
  transition: all .3s;
}

.slick-list {
  padding: 100rem 0rem !important;
}

.slick-slide img {
  width: 100%;
  object-fit: cover;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-track {
  top: 30rem;
}

.slick-slide div {
  transition: transform .25s ease-in-out;
  // opacity: .7;
}

.slick-slide.lt2 div {
  // opacity: .7;
}

.slick-slide.lt1 div {
  // opacity: .9;
}

.slick-slide.gt1 div {
  // opacity: .9;
}

.slick-slide.gt2 div {
  // opacity: .7;
}

.slick-slide.slick-center div {
  position: relative;
  z-index: 10;
  opacity: 1;
  transform: scale(1.3);
  box-shadow: 0 0 5rem 0.5rem rgba(255, 255, 255, 0.35);
  border-radius: 5rem;
}

@media screen and (max-width: 767px) {
  .slick-slide {
    width: 100rem;
    margin: 0rem 5rem;
  }
}