@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
    display: flex;
}

.dropDown {
    display: flex;
    align-items: center;
    
    cursor: pointer;

    .tokenIcon {
        width: 22.5rem;
        height: 22.5rem;
        border-radius: 50%;
    }

    .label {
        font-size: 17.5rem;
        font-weight: bold;
        line-height: 1.43;
        text-align: left;
        color: #fff;

        margin: 0 8rem 0;
    }

    .downIcon {
        width: 14rem;
        height: 8rem;
    }
}