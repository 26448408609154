@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
}

.content {
    position: relative;
    display: grid;
    grid-template-columns: 644rem 1fr;

    grid-gap: 77rem;

    margin-top: 40rem;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        grid-gap: 40rem;
    }
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 767px) {
        display: unset;
        flex-direction: unset;
        align-items: unset;
    }

    .itemLoot {
        width: 352rem;
        height: 147rem;
        object-fit: contain;

        margin-bottom: 30rem;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
        }
    }

    .lootInfo {
        flex: 1;
    }

    .title {
        -webkit-text-stroke: 0.5rem #00d2ff;
        background-image: linear-gradient(to top, #3075ff, #30aaff), linear-gradient(to bottom, #fff, #fff);
        font-size: 22.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        margin-bottom: 12rem;


    }

    .detail {
        font-size: 14rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #fff;

        margin-bottom: 34rem;
    }

    .priceContainer {
        // flex: 2;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10rem;

        @media screen and (max-width: 767px) {
            width: 100%;
            overflow-x: scroll;
        }
    }

    .priceItemLoading {
        width: 208rem;
        height: 287.5rem;
        background-color: rgb(11, 26, 92, 0.8);
        animation: $opacity-pulse;

        border-radius: 7.5rem;
    }

    .priceItem {
        display: flex;
        flex-direction: column;
        align-items: center;

        .oneX {
            width: 171rem;
            height: 70rem;
            object-fit: contain;

            @media screen and (max-width: 767px) {
                width: 190rem;
            }
        }

        .twoX {
            width: 171.5rem;
            height: 90rem;
            object-fit: contain;

            @media screen and (max-width: 767px) {
                width: 190rem;
            }
        }

        .threeX {
            width: 121rem;
            height: 98.5rem;
            object-fit: contain;

            @media screen and (max-width: 767px) {
                width: 190rem;
            }
        }

        .top {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100%;
            margin-bottom: 10rem;

            padding: 24.5rem 18.5rem 14.5rem;
            border-radius: 7.5rem;
            border: solid 0.5rem #0b1a5c;
            background-image: linear-gradient(to top, #070824, #0b1a5c);

            .itemImg {
                flex: 1;
                object-fit: contain;
                margin-bottom: 15rem;
            }

            .value {
                font-size: 22.5rem;
                font-weight: 800;
                text-align: center;
                color: #fff;
                line-height: 1;
                margin-bottom: 0;
            }

            .label {
                font-size: 11rem;
                text-align: center;
                color: #fff;
            }
        }

        .bottom {
            width: 100%;

            .zbuxButton {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                padding: 6rem;
                border-radius: 2.5rem;
                border: solid 0.5rem #f8e01a;
                background-image: linear-gradient(to top, #e1cc18, #aa7f24);

                height: 40rem;

                cursor: pointer;
                transition: all .3s;

                &.isDisabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    user-select: none;

                    &:hover {
                        transform: scale(1);
                    }
                }

                &.isStandard {
                    border: solid 0.5rem #0ec800;
                    background: #0ec800;
                }

                &:hover {
                    transform: scale(1.02);
                }

                .zbuxPrice {
                    font-size: 14rem;
                    font-weight: bold;
                    text-align: center;
                    color: #000;
                    line-height: 1;

                    margin-bottom: 0;

                    &.isStandard {
                        // color: #fff;
                    }
                }

                .zbuxToDollar {
                    font-size: 11rem;
                    font-weight: normal;
                    text-align: center;
                    color: #000;

                    margin-bottom: 0;
                }
            }

            .orDevide {
                font-size: 11rem;
                text-align: center;
                color: #8b98d1;

                margin: 5rem 0;
            }

            .undeadButton {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding: 6rem;
                border-radius: 2.5rem;
                border: solid 0.5rem #f00;
                background-image: linear-gradient(to top, #be0000, #700000);

                cursor: pointer;
                transition: all .3s;

                height: 40rem;

                &.isDisabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    user-select: none;

                    &:hover {
                        transform: scale(1);
                    }
                }

                &:hover {
                    transform: scale(1.02);
                }

                .undeadPrice {
                    font-size: 14rem;
                    font-weight: bold;
                    text-align: center;
                    color: #fff;
                    line-height: 1;

                    margin-bottom: 0;
                }

                .undeadToDollar {
                    font-size: 11rem;
                    font-weight: normal;
                    text-align: center;
                    color: #fff;

                    margin-bottom: 0;
                }
            }

            .buyByGZbuxLink {
                color: #ffffff;
                text-align: center;
                font-weight: bold;
                font-size: 11rem;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                    transform: scale(1.02);
                }
            }
        }
    }
}

.divide {
    width: 1rem;
    height: 100%;

    position: absolute;
    left: 684rem;

    @media screen and (max-width: 767px) {
        height: 220rem;
        bottom: 33.5%;
        transform: rotate(90deg);
    }
}

.right {
    // width: 100%;
    // height: 595rem;
    // padding: 25.5rem 29rem 10.5rem 27.5rem;
    // object-fit: contain;
    // border-radius: 7.5rem;
    // border: solid 0.5rem #0b1a5c;
    // background-image: linear-gradient(to top, #070824, #0b1a5c);
    // overflow-y: scroll;

    width: 100%;
    height: 595rem;
    object-fit: contain;
    border-radius: 7.5rem;
    border: solid 0.5rem #0b1a5c;
    background-image: linear-gradient(to top, #070824, #0b1a5c);

    overflow-y: hidden;
    position: relative;

    .rightheader {
        position: sticky;
        top: 0;
        padding: 25.5rem 29rem 0rem 27.5rem;
    }

    .description {
        font-size: 16rem;
        font-weight: bold;
        line-height: 1.25;
        text-align: left;
        color: #0ec800;
    }

    .devide {
        width: 100%;
        height: 0.5rem;
        background-color: #8b98d1;

        margin-bottom: 5rem;
    }

    .weaponList {
        padding: 10rem 29rem 70rem 27.5rem;
        height: 540rem;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            margin-left: 5rem;

            background: #3075ff;
        }

        .weaponItem {
            .type {
                font-size: 14rem;
                font-weight: bold;
                line-height: 2.14;
                color: #fff;

                margin-bottom: 0;
            }

            .item {
                font-size: 11rem;
                line-height: 1.6;
                color: #fff;

                margin-bottom: 0;
            }
        }
    }
}

