$font-primary: 'Inter', sans-serif;
$font-secondary: 'Inter', sans-serif;
$font-third: 'Helvetica-Neue', sans-serif;

$bg-light: #fafbfd;
$bg-white: #ffffff;
$bg-dark-gray: #9b98ac;
$bg-light-darker: #2e3448;

$bg-loading: #f0f0f0;
$bg-loading-layer: #e5e5e5;

$bg-switch-checked: #4451ee;
$bg-switch-unChecked: #7e8289;

$bg-btn-gray: #c9d1dd;

$color-border: #ededed;

$color-dark: #2c2a38;
$color-light: #fff;
$color-medium: #9b98ac;
$color-darkest: white;
$color-thin-light: #60667a;
$color-grey: #0f1528;
$color-grey-border: #1d243b;
$color-green: #37b786;
$color-purple: #AC41D1;
$color-blue: #4451ee;
$color-red: #e94949;

$color-new-dark: #0d1018;
$color-new-light:  #7e8289;
$color-new-blue: #4451ee;
$color-new-skin-blue: #0a5fe0;
$color-new-grey: #eef0f2;

$font-light: #ffffff;
$font-dark: #2c2a38;

// transition
$transition-all: all 0.15s ease-in;
$transition-color: color 0.15s ease-in;
$transition-opacity: opacity 0.15s ease-in;
