@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";

.container {
}

.character1 {
    position: absolute;
    left: 310.5rem;
    top: -84rem;
    width: 222rem;
    height: 223rem;
    z-index: -1;

    @include breakpoint("sm-max") {
        display: none;
    }
}

.character2 {
    position: absolute;
    top: 21.5rem;
    right: -80rem;
    width: 222rem;
    height: 223rem;
    z-index: -1;

    @include breakpoint("sm-max") {
        width: 147.7rem;
        height: 148rem;
        right: -22rem;
        top: 33rem;
    }
}

.character3 {
    position: absolute;
    bottom: -102.5rem;
    left: -28rem;
    width: 222rem;
    height: 223rem;
    z-index: -1;

    @include breakpoint("sm-max") {
        left: 8.3rem;
        width: 147.7rem;
        height: 147rem;
        bottom: -30rem;
    }
}