@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.wrapperBody {
    padding: 0;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0;

    @media screen and (max-width: 767px) {
        padding: 18rem 0rem;
    }

    .recent {
        width: 21.5rem;

        position: absolute;
        top: 19rem;
        right: 48rem;
        z-index: 100;

        cursor: pointer;
        transition: $transition-all;
    }

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 20rem;
        right: 20rem;
        z-index: 100;

        cursor: pointer;
        transition: $transition-all;
    }

    .divide {
        width: 100%;
        height: 1rem;
        background-color: #3075ff;
    }

    .header {
        padding: 20rem 40rem 0;
        margin-bottom: 10rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1.25;
            color: #fff;
            text-align: center;

            margin-bottom: 3rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 12.5rem;
            line-height: 1.6;
            text-align: center;
            color: #8b98d1;
        }
    }
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 17.5rem;
    font-weight: bold;
    line-height: 2.86;
    text-align: center;
    color: #fff;

    border-radius: 7.5rem;
    height: 50rem;

    background-color: #3075ff;
    cursor: pointer;
    transition: $transition-all;

    &:hover {
        box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
    }

    &.isDisabled {
        cursor: not-allowed;
        user-select: none;
        opacity: 0.7;
        background-color: gray;

        &:hover {
            box-shadow: none;
        }
    }

    @media screen and (max-width: 767px) {
    }
}

.step1 {
    margin-top: 50rem;
    padding: 0 25rem 25rem;

    .middle {
        position: relative;
        margin: 0 25rem 50rem;
        min-height: 172rem;

        @media screen and (max-width: 767px) {
            margin: 0 25rem 50rem;
        }
    }

    .item {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        border-radius: 15rem;
        padding: 20rem 27.9rem 19.5rem 22rem;

        .card {
            .row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24rem;

                .leftCol {
                    .zbuxLabel {
                        font-size: 9.8rem;
                        line-height: 1.5;
                        text-align: left;
                        color: #000;
                        margin-bottom: 0;
                    }

                    .name {
                        font-size: 17.8rem;
                        font-weight: bold;
                        line-height: 1.24;
                        text-align: left;
                        color: #000;

                        margin-bottom: 0;
                    }

                    .zLogo {
                        width: 13.5rem;
                        height: 16.5rem;

                        margin-right: 5rem;
                    }
                }

                .rightCol {
                    .blackLogo {
                        width: 42.5rem;
                        height: 24rem;
                    }

                    .zbuxOutline {
                        width: 41.5rem;
                        height: 41.5rem
                    }
                }
            }
        }
    }
}

.step2 {
    margin-top: 27rem;
    padding: 0 25rem 25rem;

    .middle {
        display: flex;
        flex-direction: column;
        transition: all 0.2s ease-in-out;

        margin-bottom: 25rem;

        &.isSwap {
            flex-direction: column-reverse;
        }
    }

    .row {
        .rowHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            :global {
                .ant-dropdown {
                    left: -50rem !important;
                }
            }

            .balanceContainer {
                font-size: 12.5rem;
                line-height: 2;
                text-align: right;
                color: #8b98d1;
            }

            .zBuxHeader {
                display: flex;
                align-items: center;

                .zBuxIcon {
                    width: 23.5rem;
                    height: 23.5rem;
                    border-radius: 50%;
                }

                .zBuxLabel {
                    font-size: 17.5rem;
                    font-weight: bold;
                    line-height: 1.43;
                    text-align: left;
                    color: #fff;

                    margin: 0 8rem 0;
                }
            }
        }

        .rowNumberInput {
            margin-top: 7.5rem;

            padding: 10rem 16rem 16rem;
            background-color: #04051e;
            border-radius: 7.5rem;
            border: solid 0.5rem #1b1850;

            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &.loading {
                @include animateSkeleton();
                padding: 10rem 16rem 16rem;
            }

            &.zbuxRow {
                padding: 10rem 16rem;
            }

            .maxBtn {
                border-radius: 5rem;
                border: solid 1rem #3075ff;
                padding: 2rem 5rem;

                font-size: 12.5rem;
                font-weight: 500;
                line-height: 1;
                text-align: center;
                color: #3075ff;

                cursor: pointer;
                transition: all 0.1s ease-in-out;

                &:hover {
                    box-shadow: 0 0 2rem 1rem #3075ff;
                }
            }
        }
    }

    .swapContainer {
        display: flex;
        justify-content: center;
        margin: 7.5rem 0;

        .swapIcon {
            width: 37.5rem;
            height: 38rem;
            border-radius: 50%;

            cursor: pointer;

            transition: all 0.1s ease-in-out;

            &:hover {
                box-shadow: 0 0 2rem 1rem #3075ff;
            }
        }
    }
}