@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
    background-image: linear-gradient(to bottom, rgba(48, 117, 255, 0), rgba(48, 117, 255, 0.35));

    border-bottom: 2.5rem solid #3075ff;

    display: flex;
}

.mainType {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26rem 9rem 12rem;

    .levelIcon {
        width: 63.5rem;
        height: 76rem;
        object-fit: cover;
    }

    .value {
        font-size: 12rem;
        font-weight: bold;
        line-height: 1.25;
        text-align: left;
        color: #fff;

        margin-bottom: 0;
    }
}

.normalType {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 22rem 0;

    .iconContainer {
        flex: 1;
        display: flex;
        align-items: center;

        .icon {
        }
    }

    .value {
        font-size: 22.5rem;
        font-weight: bold;
        color: #fff;

        flex: 1;

        margin-bottom: 0;
    }

    .label {
        font-size: 12rem;
        line-height: 1.46;
        text-align: center;
        color: #8b98d1;

        margin-bottom: 0;
    }
}