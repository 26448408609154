@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {

}

.firstLoad {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadMoreContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20rem 0 10rem;

    .loadMore {
        width: 50rem;
        height: 50rem;
    }
}



.parent {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 20rem;

        .boosterButton {
            width: 375rem;
            height: 88rem;

            object-fit: cover;
            margin-bottom: 5rem;

            cursor: pointer;
            transition: $transition-all;
    
            &:hover {
                transform: scale(1.02);
            }

            @media screen and (max-width: 767px) {
                width: 270rem;
            }
        }

        .boosterLabel {
            font-size: 14rem;
            line-height: 1.61;
            text-align: center;
            color: #fff;

            margin-bottom: 0;

            @media screen and (max-width: 767px) {
                font-size: 12rem;
            }
        }
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    
        grid-gap: 9rem 9rem;
    
        @media screen and (max-width: 767px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .emptyData {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}



.item {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    padding: 0rem 0rem 26rem 0rem;
    border-radius: 7.5rem;
    border: solid 0.5rem #0b1a5c;

    width: 230rem;
    height: 229rem;

    cursor: pointer;
    transition: all .3s;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0 0 2rem 2rem #3075ff;
    }

    @media screen and (max-width: 767px) {
        padding: 15rem 10rem;

        width: 100%;
        height: 150rem;
    }

    .name {
        font-size: 14rem;
        font-weight: bold;
        line-height: 1.07;
        text-align: left;
        color: #fff;
        text-transform: uppercase;

        text-align: center;

        margin-bottom: 0;

        @media screen and (max-width: 767px) {
            font-size: 10rem;
        }
    }
}