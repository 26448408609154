@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    width: 100%;
}

.loadingBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 15rem;
    font-weight: bold;
    line-height: 2.86;
    text-align: center;
    color: #fff;

    border-radius: 7.5rem;
    height: 40rem;
    padding: 0 10rem;
    min-width: 150rem;


    background-color: #3075ff;
    cursor: pointer;
    transition: $transition-all;

    &:hover {
        box-shadow: 0 0 10rem 0.5rem rgba(132, 33, 255, 0.6);
    }

    &.isDisabled {
        cursor: not-allowed;
        user-select: none;
        opacity: 0.7;
        background-color: gray;

        &:hover {
            box-shadow: none;
        }
    }

    @media screen and (max-width: 767px) {
    }
}