@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";

.container {
  padding: 33rem 25rem 0;
}

.content {
  display: flex;
  justify-content: space-between;
}

.left {
  .logo {
    width: 52rem;
    margin-right: 30rem;
  
    @include breakpoint("sm-max") {
      width: 57.3rem;
      margin-right: 0rem;
    }
  }
}