@import "/src/styles/mixins/breakpoints";
@import "/src/styles/variables/common";

.container {

}

.navWrapped {
  padding: 25rem 0 0;
  margin-bottom: 0;
}

.main {
  width: 100%;
  overflow: visible;
  min-height: 100vh;

  @include breakpoint("sm-max") {
    min-height: calc(100vh - 174px);
  }

  .dashboard & {
    min-height: 100vh;
    background: var(--bg-page);
  }
}

.fade {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.5);

  visibility: hidden;
  opacity: 0;
  transition: $transition-all;

  &.fadeActive {
    visibility: visible;
    opacity: 1;
  }
}