@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
    :global {
        .ant-modal-content {
            background-image: linear-gradient(to top, #070824, #0b1a5c);
        }
    }
}

.wrapperBody {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15rem;

    @media screen and (max-width: 767px) {
        padding: 0 10rem;
    }

    .close {
        width: 16.5rem;
        height: 16.5rem;

        position: absolute;
        top: 20rem;
        right: 20rem;

        cursor: pointer;
        transition: $transition-all;
    
        &:hover {
            transform: scale(1.05);
        }
    }

    .header {
        margin-bottom: 15rem;

        .heading {
            font-size: 20rem;
            font-weight: bold;
            line-height: 1.3;
            color: #fff;
            text-align: center;

            margin-bottom: 6rem;

            @media screen and (max-width: 767px) {
                font-size: 16rem;
            }
        }

        .detail {
            font-size: 10rem;
            line-height: 1.6;
            text-align: center;
            color: #8b98d1;

            margin-bottom: 0;
        }
    }

    .box {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;

        @media screen and (max-width: 767px) {
            margin-top: 10rem;
            padding: 0 10rem;
        }

        .leftBubble {
            position: absolute;
            left: 15rem;
            top: 105rem;

            width: 120rem;
            height: 120rem;
        }

        .rightBubble {
            position: absolute;
            top: 10rem;
            right: 30rem;

            width: 101rem;
            height: 101rem;
        }

        .coffinIcon {
            // width: 314rem;
            // height: 163rem;
        }
    }

    .buttonContainer {
        display: flex;
        margin-top: 30rem;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .viewArmory {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 193rem;
        height: 37.5rem;
        border-radius: 2.5rem;
        background-color: #3075ff;

        display: flex;
        font-size: 14rem;
        font-weight: bold;
        text-align: center;
        color: #fff;

        margin-right: 7rem;

        cursor: pointer;
        transition: all .3s;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 10rem;
        }
    }

    .viewLoot {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 193rem;
        height: 37.5rem;
        border-radius: 2.5rem;
        background-color: #fff;

        margin-left: 7rem;

        cursor: pointer;
        transition: all .3s;

        display: flex;
        font-size: 14rem;
        font-weight: bold;
        text-align: center;
        color: #3075ff;

        &:hover {
            transform: scale(1.02);
        }

        @media screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
}
