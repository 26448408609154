@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/mixins/common";
@import "/src/styles/animations";


.container {
  :global {
    .ant-upload-picture-card-wrapper {
      width: auto;
    }

    .ant-upload-list-text-container {
      display: none;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 20% 55% 25%;
  align-items: center;
  margin-bottom: 8rem;

  @include breakpoint("sm-max") {
    grid-template-columns: 100%;
    margin-bottom: 0;
    padding: 15rem 10rem;
  }

  .uploadContainer {
    .cameraContainer {
      width: 74rem;
      height: 74rem;

      position: relative;
      cursor: pointer;

      &:hover {
        .avatarImg {
          box-shadow: 0 0 8rem 2rem #3075ff;
        }
      }

      &.loading {
        animation: $opacity-pulse;
      }

      &.customUpload {
        width: 110rem;
        height: 110rem;
      }

      .progress {
        font-size: 17.5rem;
        font-weight: bold;
        line-height: normal;
        color: #fff;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .circle {
        width: 100%;
      }

      .camera {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 26rem;
        height: 21rem;

        &.customUpload {
          width: 32rem;
          height: 25rem;
        }
      }

      .avatarImg {
        width: 74rem;
        height: 74rem;
        border-radius: 50%;
        // box-shadow: 0 0 0rem 2rem #2d6ced;
        object-fit: cover;

        &:hover {
        }

        &.customUpload {
          width: 110rem;
          height: 110rem;
        }
      }

      .verifiedContainer {
        position: absolute;

        bottom: 0;
        right: 0;

        transition: $transition-all;

        .verifiedImg {
          width: 21rem;
          height: 21rem;

          position: relative;

          bottom: 0;
          right: 0;

          &.customUpload {
            width: 32rem;
            height: 32rem;
          }
        }

        .pencil {
          width: 11.5rem;
          height: 11rem;

          position: absolute;

          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &.customUpload {
            width: 16.5rem;
            height: 16.5rem;
          }
        }
      }
    }
  }
}