@import "/src/styles/variables/common";
@import "/src/styles/mixins/breakpoints";
@import "/src/styles/animations";

.container {
}

.firstLoad {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadMoreContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20rem 0 10rem;

    .loadMore {
        width: 50rem;
        height: 50rem;
    }
}

.devide {
    background-image: linear-gradient(to right, rgba(139, 152, 209, 0), rgba(139, 152, 209, 1), rgba(139, 152, 209, 0));
    width: 90%;
    height: 1rem;

    &.top {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        width: 499rem;
        height: 57.5rem;

        object-fit: cover;
        margin-bottom: 20rem;

        @media screen and (max-width: 767px) {
            width: 280rem;
            object-fit: contain;
        }
    } 

    .description {
        font-size: 14rem;
        line-height: 1.61;
        text-align: center;
        color: #fff;
    }

    .typeWeaponContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media screen and (max-width: 767px) {
            grid-template-columns: 1fr 1fr;
        }

        .item {
            padding: 11rem 11rem;
            cursor: pointer;
            transition: $transition-all;

            &:hover {
                .common {
                    background: url('../../../../assets/dashboardLayout/common-hover.png') no-repeat center / 120% 165%;
                }

                .rare {
                    background: url('../../../../assets/dashboardLayout/rare-hover.png') no-repeat center / 120% 165%;
                }
    
                .epic {
                    background: url('../../../../assets/dashboardLayout/epic-hover.png') no-repeat center / 120% 165%;
                }
    
                .legendary {
                    background: url('../../../../assets/dashboardLayout/legendary-hover.png') no-repeat center / 120% 165%;
                }
            }

            &.wait {
                &:hover {
                    cursor: wait;
                }
            }

            &.hasRightBorder {
                border-right: 1rem solid #8b98d1;
            }

            &.isCommon {
                @media screen and (max-width: 767px) {
                    border-bottom: 1rem solid #8b98d1;
                }
            }

            &.isRare {
                @media screen and (max-width: 767px) {
                    border-right: unset;
                    border-bottom: 1rem solid #8b98d1;
                }
            }

            .common {
                background: url('../../../../assets/dashboardLayout/common.png') no-repeat center / 120% 165%;
            }

            .rare {
                background: url('../../../../assets/dashboardLayout/rare.png') no-repeat center / 120% 165%;
            }

            .epic {
                background: url('../../../../assets/dashboardLayout/epic.png') no-repeat center / 120% 165%;
            }

            .legendary {
                background: url('../../../../assets/dashboardLayout/legendary.png') no-repeat center / 120% 165%;
            }


            .typeIcon {
                width: 187.5rem;
                height: 42.5rem;
                transition: $transition-all;
                border-radius: 7rem;

                &.isSelected {
                    box-shadow: 0 0 10rem 3rem #fff;
                    border-radius: 7rem;
                }

                @media screen and (max-width: 767px) {
                    width: 150rem;
                    height: 35rem;
                }
            }
        }
    }

    .actionButton {
        display: flex;
        gap: 20rem;

        margin: 10.5rem 0;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }

        .infoButton {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6rem;
            width: 150rem;

            border-radius: 2.5rem;
            border: solid 0.5rem #0b1a5c;
            background-image: linear-gradient(to top, #070824, #0b1a5c);

            cursor: pointer;
            transition: $transition-all;

            // &:hover {
            //     background: #3075ff;
            // }

            &.isEnable {
                background: #3075ff;
            }

            @media screen and (max-width: 767px) {
                height: 57rem;
            }

            .infoLabel {
                font-size: 14rem;
                text-align: center;
                line-height: 0.8;
                color: #fff;

                margin-bottom: 0;
            }

            .infoIcon {
                width: 15rem;
                height: 15rem;
            }
        }

        .neededButton {
            border-radius: 2.5rem;
            border: solid 0.5rem #0b1a5c;
            background-image: linear-gradient(to top, #070824, #0b1a5c);

            width: 180rem;

            padding: 9.5rem 10rem 5rem;

            .countLabel {
                font-size: 22.5rem;
                font-weight: bold;
                text-align: center;
                color: #fff;

                margin-bottom: 0;
            }

            .loadoutLabel {
                font-size: 14rem;
                text-align: center;
                line-height: 0.8;
                color: #fff;

                margin-bottom: 0;
            }
        }

        .boostButton {
            border-radius: 2.5rem;
            border: solid 0.5rem #0b1a5c;
            background-image: linear-gradient(to top, #070824, #0b1a5c);

            width: 150rem;

            padding: 9.5rem 20rem 5rem;

            font-size: 27.5rem;
            font-weight: 900;
            text-align: center;
            color: #fff;

            cursor: not-allowed;
            user-select: none;
            transition: $transition-all;

            &.isSelected {
                background-image: linear-gradient(to top, #490000, #c10000);
                cursor: pointer;
                user-select: unset;
                color: #000000;

                &:hover {
                    transform: scale(1.02);
                }
            }

            &.isDisabled {
                opacity: 0.5;
                user-select: none;
                cursor: not-allowed;
            }
        }
    }
}